import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';

class HeUsers extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			peopleList: this.copyTable(peopleList),
			lookup: {
				PERSON_ID: null
			},
			search: {
				LNAME: '',
				FNAME: '',
				PI_ROLE_ID: null,
				SUPERVISOR_ID: null,
				TYPE_ID: null,
				ACTIVE_FLAG: 'Y'
			},
			searchCount: 0,
			renderKey: 0
		};
	}
	
	onChangeLookup(accessor, value) {
		var lookup = this.copyObject(this.state.lookup);
		this.setByAccessor(lookup, accessor, value);
		this.mergeState({ lookup: lookup });
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitLookup(event) {
		this.props.navigate('/HeLAD/People/' + this.state.lookup.PERSON_ID);
	}

	onSubmitSearch(event) {
		var newPeopleList = this.copyTable(peopleList);
		newPeopleList.ajax.data = {
			search: this.copyObject(this.state.search),
		};
		newPeopleList.hold = false;
		this.mergeState({ 
			peopleList: newPeopleList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	editPerson(personId) {
		this.props.navigate('/HeLAD/People/' + personId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			columns: exportColumns
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Finding People</span><br />
						<hr />
						<h6>Retrieve a Current Individual</h6>
						<ChemEdit parent={this} user={this.props.user} columns={peopleLookup} data={this.state.lookup}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeLookup(accessor, value)}
						  onSubmit={(event) => this.onSubmitLookup(event)}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col>
						  	  <Button id="retrieve" variant="warning" type="submit" className="float-end">Retrieve</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
						<hr />
						<h6>Quick Search</h6>
						<ChemEdit parent={this} user={this.props.user} columns={peopleSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="search" variant="warning" type="button" className="float-end"
						  onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_DemPeople' table={this.state.peopleList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='185px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const peopleLookup = [
	{ Header: 'Current Individual', accessor: 'PERSON_ID', type: 'select', row: 0, col: 0,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'DEMURRAGE.DEM_USER_SELECT_VW', order: 'USER_NAME' }}
];

const peopleSearch = [
	{ Header: 'Last Name', accessor: 'LNAME' },
	{ Header: 'First Name', accessor: 'FNAME' },
	{ Header: 'PI', accessor: 'PI_ROLE_ID', type: 'select',
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'DEMURRAGE.DEM_PI_SELECT_VW', order: 'PI_NAME' }},
	{ Header: 'Supervisor', accessor: 'SUPERVISOR_ID', type: 'select',
		options: { value: 'ROLE_ID', label: 'SUP_NAME', entity: 'DEMURRAGE.DEM_SUPERVISORS_VW', order: 'SUP_NAME' }},
	{ Header: 'Appointment', accessor: 'TYPE_ID', type: 'select',
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'COLLEGE.ROLE_TYPES', order: 'TYPE_NAME' }},
	{ Header: 'Active Flag', accessor: 'ACTIVE_FLAG', type: 'select',
		options: [{ value: 'Y', label: 'Yes' },{ value: 'N', label: 'No' }]}
];

const peopleList = {
	ajax: { url: '/HeLAD/GetDemPeople', accessor: 'Data' },
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: '', accessor: 'PERSON_ID', show: false },
		{ Header: 'Name', accessor: 'PERSON_NAME', width: 250,
			Cell: props => <HashLink onClick={() => props.parent.editPerson(props.row.values.PERSON_ID)}>{props.value}</HashLink> },
		{ Header: 'Appt.', accessor: 'TYPE_NAME', width: 200, },
		{ Header: 'PI', accessor: 'PI_NAME', width: 250 },
		{ Header: 'Phone', accessor: 'PHONE', width: 150 },
		{ Header: 'Email', accessor: 'EMAIL', width: 200,
			Cell: props => <a href={'mailto:' + props.value} target='_blank' rel='noreferrer'>{props.value}</a> },
		{ Header: 'Location', accessor: 'LOCATION', width: 250 }
	]
};

const exportColumns = [
	{ Header: 'Name', accessor: 'PERSON_NAME', width: 50 },
	{ Header: 'Appt.', accessor: 'TYPE_NAME', width: 40, },
	{ Header: 'PI', accessor: 'PI_NAME', width: 50 },
	{ Header: 'Phone', accessor: 'PHONE', width: 30 },
	{ Header: 'Email', accessor: 'EMAIL', width: 40 },
	{ Header: 'Location', accessor: 'LOCATION', width: 50 }
];

export default withRouter(HeUsers);
