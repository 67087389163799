import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Person extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			person: {
				person: {
					PERSON_ID: null
				},
				work_info: {},
				roles: {},
				addresses: {}
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadPerson();
	}

	loadPerson(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/HeLAD/GetDemPerson',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				personid: this.props.params.personId
			}
		}).done(function (data) {
			if (data.Success) {
				// trim all names so they will display correctly
				data.Data.person.LNAME = self.isEmpty(data.Data.person.LNAME) ? '' : data.Data.person.LNAME.trim();
				data.Data.person.FNAME = self.isEmpty(data.Data.person.FNAME) ? '' : data.Data.person.FNAME.trim();
				data.Data.person.MNAME = self.isEmpty(data.Data.person.MNAME) ? '' : data.Data.person.MNAME.trim();
				data.Data.person.PREFERRED_NAME = self.isEmpty(data.Data.person.PREFERRED_NAME) ? '' : data.Data.person.PREFERRED_NAME.trim();
				
				self.mergeState({
					person: data.Data
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
		
	onChange(accessor, value) {
		var person = this.copyObject(this.state.person);
		this.setByAccessor(person, accessor, value);
		this.mergeState({ person: person, renderKey: this.state.renderKey + 1 });
	}
	
	composePersonName() {
		var person_detail = this.state.person.person;
		var person_name = person_detail.LNAME;

		// only add a comma if there is something on both sides of the comma
		if (!this.isEmpty(person_name) && (!this.isEmpty(person_detail.FNAME) || !this.isEmpty(person_detail.MNAME) || !this.isEmpty(person_detail.PREFERRED_NAME))) {
			person_name += ',';
		}

		if (!this.isEmpty(person_detail.FNAME)) person_name += ' ' + person_detail.FNAME;
		if (!this.isEmpty(person_detail.MNAME)) person_name += ' ' + person_detail.MNAME;
		if (!this.isEmpty(person_detail.PREFERRED_NAME)) person_name += ' (' + person_detail.PREFERRED_NAME + ')';
		
		return person_name;
	}
		
	onSubmit(event) {
		var self = this;		
		
		// copy just the data needed for update
		var address = {
			PERSON_ID: this.state.person.person.PERSON_ID,
			TEMP_STREET1: this.state.person.addresses.TEMP_STREET1,
			TEMP_PHONE1: this.state.person.addresses.TEMP_PHONE1,
			TEMP_EMAIL: this.state.person.addresses.TEMP_EMAIL
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/HeLAD/UpdateTempAddress',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, address: address }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Temporary address updated for <Link to={'/HeLAD/People/' + self.props.params.personId}>{self.composePersonName()}</Link>.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	renderPrintLabel(personId) {
		
		var labelData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			personid: this.props.params.personId
		});
		
		return (
			<form action={this.getConfig().host + '/HeLAD/PrintAddressLabel'} method='POST'>
				{labelData.map((pdfFormField, index) => {
					return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
				})}
				<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Mailing Label</Popover.Header></Popover>}>
					<input type='image' src={this.getConfig().host + '/Content/Icons/pdf.png'} alt="Print Barcode" />
				</OverlayTrigger>
			</form>
		);
	}

	render() {		
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Detail Page for {this.composePersonName()}</div>
				<ChemEdit parent={this} columns={personColumns} data={this.state.person} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const personColumns = [
	{ Header: 'First Name', accessor: 'person.FNAME', row: 0, col: 0, editable: false },
	{ Header: 'Last Name', accessor: 'person.LNAME', row: 0, col: 1, editable: false },
	{ Header: 'Middle Name', accessor: 'person.MNAME', row: 0, col: 2, editable: false },
	{ Header: 'Preferred Name', accessor: 'person.PREFERRED_NAME', row: 0, col: 3, editable: false },
	{ Header: 'Department', accessor: 'person.DEPARTMENT', row: 1, col: 0, editable: false },
	{ Header: 'Start Date', accessor: 'person.START_DATE', row: 1, col: 1, editable: false },
	{ Header: 'End Date', accessor: 'person.END_DATE', row: 1, col: 2, editable: false },
	{ Header: 'Status', accessor: 'person.ACTIVE_FLAG', row: 1, col: 3, editable: false },
	{ Header: 'Location', accessor: 'work_info.LOCATION', row: 2, col: 0, editable: false },
	{ Header: 'Phones', accessor: 'work_info.PHONE1', row: 2, col: 1, editable: false },
	{ Header: 'Email', accessor: 'work_info.EMAIL', row: 2, col: 2, editable: false,
		Cell: props => <a href={'mailto:' + props.value} target='_blank' rel='noreferrer'>{props.value}</a> },
	{ Header: 'Forwarding Address', accessor: 'addresses.FORWARDING_ADDRESS', row: 2, col: 3, editable: false },
	{ Header: 'PI', accessor: 'roles.PI_NAME', row: 3, col: 0, editable: false },
	{ Header: 'Appointment', accessor: 'roles.TYPE_NAME', row: 3, col: 1, editable: false },
	{ Header: 'Unit', accessor: 'roles.UNIT_NAME', row: 3, col: 2, editable: false },
	{ Header: 'Label', accessor: 'person.PERSON_ID', row: 3, col: 3, editable: false,
		Cell: props => props.parent.renderPrintLabel(props.value) },
	{ Header: 'Temp Location', accessor: 'addresses.TEMP_STREET1', row: 4, col: 0 },
	{ Header: 'Temp Phone', accessor: 'addresses.TEMP_PHONE1', row: 4, col: 1 },
	{ Header: 'Temp Email', accessor: 'addresses.TEMP_EMAIL', row: 4, col: 2 }
];

export default withRouter(Person);
