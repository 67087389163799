import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';

class Rates extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			rates: {
				ID: null,
				XRAYDIFFSTUDENT: '',
				XRAYDIFFSERVICE: '',
				XRAYDIFFRECRYS: '',
				XRAYDIFFOUT: '',
				XRAYMATERIAL: '',
				CHN: '',
				CHNMATERIAL: '',
				CHNOUT: '',
				CHNOUTMATERIAL: '',
				CHNA: '',
				CHNAMATERIAL: '',
				CHNAOUT: '',
				CHNAOUTMATERIAL: '',
				CHNS: '',
				CHNSMATERIAL: '',
				CHNSOUT: '',
				CHNSOUTMATERIAL: '',
				CHNS_CHNA_LABOR: '',
				CHNS_CHNA_MATERIAL: '',
				ICP: '',
				ICPMATERIAL: '',
				CONSULTING: '',
				CONSULTINGOUT: ''
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadRates();
	}

	loadRates(event) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/FAS/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'LABRATES',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					self.mergeState({
						rates: data.Data[0],
						renderKey: self.state.renderKey + 1
					}, () => {
						if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
					});
				} else {
					self.showAlert('Rates Error', 'Expected 1 set of lab rates but found ' + data.Data.length);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
		
	onChange(accessor, value) {
		var rates = this.copyObject(this.state.rates);
		this.setByAccessor(rates, accessor, value);
		this.mergeState({ rates: rates, renderKey: this.state.renderKey + 1 });
	}
	
	validateRates(data) {
		return this.validate(xrayColumns, data, microColumns, data);
	}

	onSubmit(event) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/FAS/UpdateRates',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				rates: this.copyObject(this.state.rates)
			}
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Chemistry Recharge Rates updated.
					</>);
				});				
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
						  Chemistry Recharge Rates
						</div>
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
						<h6>X-Ray Rates</h6>
						<ChemEdit parent={this} user={this.props.user} columns={xrayColumns} data={this.state.rates}
							onChange={(accessor, value) => this.onChange(accessor, value)} renderKey={this.state.renderKey} />
						<h6>Microlab Rates</h6>
						<ChemEdit parent={this} user={this.props.user} columns={microColumns} data={this.state.rates}
							onChange={(accessor, value) => this.onChange(accessor, value)} renderKey={this.state.renderKey}
							validate={(event, columns, data) => this.validateRates(data)} onSubmit={(event) => this.onSubmit(event)}>
							<Form.Group as={Row}>
							  <Col style={{ borderTop: '1px solid #eee', paddingTop: '5px' }}>
								<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
							  </Col>
							</Form.Group>							
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const xrayColumns = [
	{ Header: 'X-Ray Diffraction Student Run Structure', accessor: 'XRAYDIFFSTUDENT' },
	{ Header: 'X-Ray Diffraction Service Structure', accessor: 'XRAYDIFFSERVICE' },
	{ Header: 'X-Ray Diffraction Recrysatlize or Training', accessor: 'XRAYDIFFRECRYS' },
	{ Header: 'X-Ray diffraction - Outside Customer', accessor: 'XRAYDIFFOUT' },
	{ Header: 'X-Ray Material Cost', accessor: 'XRAYMATERIAL' }
];

const microColumns = [
	{ Header: 'CHN Labor', accessor: 'CHN' },
	{ Header: 'CHN Material', accessor: 'CHNMATERIAL' },
	{ Header: 'CHN Outside Labor', accessor: 'CHNOUT' },
	{ Header: 'CHN Outside Material', accessor: 'CHNOUTMATERIAL' },
	{ Header: 'CHNA Labor', accessor: 'CHNA' },
	{ Header: 'CHNA Material', accessor: 'CHNAMATERIAL' },
	{ Header: 'CHNA Outside Labor', accessor: 'CHNAOUT' },
	{ Header: 'CHNA Outside Material', accessor: 'CHNAOUTMATERIAL' },
	{ Header: 'CHNS Labor', accessor: 'CHNS' },
	{ Header: 'CHNS Material', accessor: 'CHNSMATERIAL' },
	{ Header: 'CHNS Outside Labor', accessor: 'CHNSOUT' },
	{ Header: 'CHNS Outside Material', accessor: 'CHNSOUTMATERIAL' },
	{ Header: 'CHNS + CHNA Labor', accessor: 'CHNS_CHNA_LABOR' },
	{ Header: 'CHNS + CHNA Material', accessor: 'CHNS_CHNA_MATERIAL' },
	{ Header: 'ICP Labor', accessor: 'ICP' },
	{ Header: 'ICP Material', accessor: 'ICPMATERIAL' },
	{ Header: 'Consulting', accessor: 'CONSULTING' },
	{ Header: 'Outside Consulting', accessor: 'CONSULTINGOUT' }
];

export default withRouter(Rates);
