import ChemComponent from '../ChemComponent';
import ChemEdit from "../ChemEdit";
import ChemTable from "../ChemTable";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

class MyAppointments extends ChemComponent {
	constructor(props) {
		super(props);

		// add person ID to pending request query
		var pendingRequestQuery = this.mergeObject(pendingRequestApproval.query, { search: { LongValue: props.user.personID }});
		
		this.state = {
			appointments: [],
			newAppointment: {
				PERSON_ID: props.user.personID,
				TYPE_ID: null,
				SPEEDTYPE: null,
				EXISTING_STORES_ACCESS: false,
				STORES_ACCESS: false,
				EXISTING_BIXBY_ACCESS: false,
				BIXBY_ACCESS: false,
				CHANGE_FLAG: true,
				Keys: [],
				Access: [],
				selectedLocation: null,
				selectedBuilding: null,
				selectedRoom: null,
				newAppointmentFlag: true
			},
			pendingRequestQuery: pendingRequestQuery,
			pendingRequests: [],
			buildings: [],
			rooms: {},
			locations: [],
			pi: null,
			renderKey: 0
		};
	}

	componentDidMount() {
		var self = this;
		
		this.loadNewRequestId(() => {
			self.search({ query: this.state.pendingRequestQuery }, (searchResults) => {
				self.mergeState({
					pendingRequests: searchResults.Data
				}, () => {
					self.loadAppointments();
				});
			});
		});
	}
	
	loadNewRequestId(callback) {
		var self = this;
		
		// if there is no person ID
		if (this.props.user.personID === null) {
			// search for a request ID
			this.search({
				query: {
				columns: 'REQ_ID',
				entity: 'NEW_REQUEST',
				search: { Attribute: 'CAL_UID', Operator: '=', LongValue: this.props.user.uid }
			}}, (searchResults) => {
				// if a new request was found
				if (searchResults.Data.length > 0) {
					// use the request ID as the person ID
					self.mergeState({
						pendingRequestQuery: {
							search: {
								LongValue: searchResults.Data[0].REQ_ID
							}
						},
						newAppointment: {
							PERSON_ID: searchResults.Data[0].REQ_ID,
							CHANGE_FLAG: false
						},
						renderKey: self.state.renderKey + 1
					}, () => {
						// invoke callback
						callback();
					});
				} else {
					self.showAlert('User Not Found', 'No users or new user requests could be found');
				}
			});
		} else {
			// no lookup necessary, invoke callback directly
			callback();
		}
	}
	
	loadAppointments() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/BuildingAccess/GetAppointments',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personId: this.props.user.personID }
		}).done(function (data) {
			if (data.Success) {
				var i;
				var existingStoresAccess = false;
				var existingBixbyAccess = false;
				var currentRoleID = null;
				
				// for each access, note if it is storeroom/bixby
				for (i = 0; i < data.PeopleAccess.length; i++) {
					if (data.PeopleAccess[i].LOC_ACCESS_ID === 1) {
						existingStoresAccess = true;
					} else if (data.PeopleAccess[i].LOC_ACCESS_ID === 21) {
						existingBixbyAccess = true;
					}
				}
				
				// find defaults for location, building, room
				var defaultLocation = data.Locations.length > 0 ? data.Locations[0].LOC_ACCESS_ID : null;
				var defaultBuilding = data.Buildings.length > 0 ? data.Buildings[0].BUILDING_ID : null;
				var buildingStateID = self.getBuildingStateID(defaultBuilding);
				var rooms = data.Rooms[buildingStateID];
				var defaultRoom = (rooms && rooms.length > 0) ? rooms[0].ROOM_ID : null;

				// for each appointment
				var appointments = [];
				for (i = 0; i < data.Appointments.length; i++) {
					var apptView = data.Appointments[i].AppointmentView;
					
					// if PI name should be suffixed with type
					if (apptView.TYPE_ID > 4 && apptView.TYPE_ID < 9) {
						// add type to PI name
						apptView.PI_NAME += ' ' + apptView.PI_TYPE;
					}
					
					// if there is no end date
					if (!apptView.END_ROLE_DATE) {
						// use role ID for current role
						currentRoleID = apptView.ROLE_ID;
					}
					
					// assign existing access
					apptView.EXISTING_STORES_ACCESS = existingStoresAccess;

					// compose MODIFIED field
					apptView.MODIFIED = self.dateTimeToString(self.getDate(apptView.ROLE_MODIF_DATE)) + ' by ' + apptView.ROLE_MODIF_BY;
					
					// initialize keys, access
					apptView.Keys = [];
					apptView.Access = [];
					apptView.selectedLocation = defaultLocation;
					apptView.selectedBuilding = defaultBuilding;
					apptView.selectedRoom = defaultRoom;
					apptView.newAppointmentFlag = false;
					
					appointments.push(apptView);
				}
				
				self.mergeState({
					appointments: appointments,
					newAppointment: {
						EXISTING_STORES_ACCESS: existingStoresAccess,
						EXISTING_BIXBY_ACCESS: existingBixbyAccess,
						ROLE_ID: currentRoleID,
						selectedLocation: defaultLocation,
						selectedBuilding: defaultBuilding,
						selectedRoom: defaultRoom
					},
					buildings: data.Buildings,
					rooms: data.Rooms,
					locations: data.Locations,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	deleteRequest(reqid) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Request', 'Are you sure you want to delete this request?', (okButtonPressed) => {
			if (okButtonPressed) {
				// determine if this is a new person as well as a new appointment
				var newperson = this.props.user.personID === null;
				
				this.ajax({
					type: 'post',
					url: this.getConfig().host + '/BuildingAccess/DeleteRoleRequest',
					data: { __RequestVerificationToken: this.props.user.antiForgeryToken, reqid: reqid, newperson: newperson }
				}).done(function (data) {
					if (data.Success) {
						// reload pending requests
						self.search({ query: self.state.pendingRequestQuery }, (searchResults) => {
							self.mergeState({
								pendingRequests: searchResults.Data,
								renderKey: self.state.renderKey + 1
							});
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});			
	}
	
	findAppointmentIdx(roleID) {
		for (var i = 0; i < this.state.appointments.length; i++) {
			if (this.state.appointments[i].ROLE_ID === roleID) return i;
		}
		return -1;
	}
	
	getBuildingStateID(buildingID) {
		return 'BUILDING_' + buildingID;
	}
	
	getNewRowID(rows) {
		var maxId = -1;
		for (var i = 0; i < rows.length; i++) {
			if (rows[i].id > maxId) maxId = rows[i].id;
		}
		return maxId + 1;
	}

	getBuildingName(buildingID) {
		for (var i = 0; i < this.state.buildings.length; i++) {
			if (this.state.buildings[i].BUILDING_ID === buildingID) {
				return this.state.buildings[i].BUILDING_NAME;
			}
		}
		
		return null;
	}
	
	getLocationName(locAccessID) {
		for (var i = 0; i < this.state.locations.length; i++) {
			if (this.state.locations[i].LOC_ACCESS_ID === locAccessID) {
				return this.state.locations[i].LOC_ACCESS_NAME;
			}
		}
		
		return null;
	}
	
	getRoomName(buildingID, roomID) {
		var bldgStateID = this.getBuildingStateID(buildingID);
		
		var rooms = this.state.rooms[bldgStateID];
		
		for (var i = 0; i < rooms.length; i++) {
			if (rooms[i].ROOM_ID === roomID) return rooms[i].ROOM_NAME;
		}
		
		return null;
	}

	addAccessRequest(event, apptIdx) {	
		if (apptIdx < 0) {
			// add to new appointment
			var newAppointment = this.copyObject(this.state.newAppointment);
			newAppointment.Access.push({
				id: this.getNewRowID(newAppointment.Access),
				ROLE_ID: null,
				LOC_ACCESS_ID: +newAppointment.selectedLocation,
				LOC_ACCESS_NAME: this.getLocationName(+newAppointment.selectedLocation)
			});
			this.mergeState({
				newAppointment: newAppointment,
				renderKey: this.state.renderKey + 1
			});
		} else {
			//add to existing appointment
			var appointments = this.copyObject(this.state.appointments);
			appointments[apptIdx].Access.push({
				id: this.getNewRowID(appointments[apptIdx].Access),
				ROLE_ID: appointments[apptIdx].ROLE_ID,
				LOC_ACCESS_ID: +appointments[apptIdx].selectedLocation,
				LOC_ACCESS_NAME: this.getLocationName(+appointments[apptIdx].selectedLocation)
			});
			this.mergeState({
				appointments: appointments,
				renderKey: this.state.renderKey + 1
			});
		}
	}

	addKeyRequest(event, apptIdx) {
		if (apptIdx < 0) {
			// add key request to new appointment
			var newAppointment = this.copyObject(this.state.newAppointment);
			newAppointment.Keys.push({
				id: this.getNewRowID(newAppointment.Keys),
				ROLE_ID: null,
				BUILDING_ID: +newAppointment.selectedBuilding,
				BUILDING_NAME: this.getBuildingName(+newAppointment.selectedBuilding),
				ROOM_ID: +newAppointment.selectedRoom,
				ROOM_NAME: this.getRoomName(+newAppointment.selectedBuilding, +newAppointment.selectedRoom)
			});
			this.mergeState({
				newAppointment: newAppointment,
				renderKey: this.state.renderKey + 1
			});
		} else {
			// add key request to existing appointment
			var appointments = this.copyObject(this.state.appointments);
			appointments[apptIdx].Keys.push({
				id: this.getNewRowID(appointments[apptIdx].Keys),
				ROLE_ID: appointments[apptIdx].ROLE_ID,
				BUILDING_ID: +appointments[apptIdx].selectedBuilding,
				BUILDING_NAME: this.getBuildingName(+appointments[apptIdx].selectedBuilding),
				ROOM_ID: +appointments[apptIdx].selectedRoom,
				ROOM_NAME: this.getRoomName(+appointments[apptIdx].selectedBuilding, +appointments[apptIdx].selectedRoom)
			});
			this.mergeState({
				appointments: appointments,
				renderKey: this.state.renderKey + 1
			});
		}
	}

	deleteAccessRequest(props) {
		var i;
		
		// get role ID
		var roleID = props.row.values.ROLE_ID;
		
		// get appointment index
		var apptIdx = this.findAppointmentIdx(roleID);
		
		if (apptIdx < 0) {
			// update new appointment
			var newAppointment = this.copyObject(this.state.newAppointment);
			
			// for each location
			for (i = 0; i < newAppointment.Access.length; i++) {
				// if the id matches
				if (newAppointment.Access[i].id === props.value) {
					// remove from access array
					newAppointment.Access.splice(i, 1);
					break;
				}
			}
			
			this.mergeState({ 
				newAppointment: newAppointment,
				renderKey: this.state.renderKey + 1
			});
		} else {
			// update existing appointment
			var appointments = this.copyObject(this.state.appointments);
			
			// for each location
			for (i = 0; i < appointments[apptIdx].Access.length; i++) {
				// if the id matches
				if (appointments[apptIdx].Access[i].id === props.value) {
					// remove from access array
					appointments[apptIdx].Access.splice(i, 1);
					break;
				}
			}
			
			this.mergeState({
				appointments: appointments,
				renderKey: this.state.renderKey + 1
			});
		}
	}

	deleteKeyRequest(props) {		
		var i;
		
		// get role ID
		var roleID = props.row.values.ROLE_ID;
		
		// get appointment index
		var apptIdx = this.findAppointmentIdx(roleID);
		
		if (apptIdx < 0) {
			// update new appointment
			var newAppointment = this.copyObject(this.state.newAppointment);
			
			// for each key
			for (i = 0; i < newAppointment.Keys.length; i++) {
				// if the id matches
				if (newAppointment.Keys[i].id === props.value) {
					// remove from keys array
					newAppointment.Keys.splice(i, 1);
					break;
				}
			}
			
			this.mergeState({ 
				newAppointment: newAppointment,
				renderKey: this.state.renderKey + 1
			});
		} else {
			// update existing appointment
			var appointments = this.copyObject(this.state.appointments);
			
			// for each key
			for (i = 0; i < appointments[apptIdx].Keys.length; i++) {
				// if the id matches
				if (appointments[apptIdx].Keys[i].id === props.value) {
					// remove from keys array
					appointments[apptIdx].Keys.splice(i, 1);
					break;
				}
			}
			
			this.mergeState({
				appointments: appointments,
				renderKey: this.state.renderKey + 1
			});
		}
	}

	onChangeExisting(roleID, accessor, value) {
		var apptIdx = this.findAppointmentIdx(roleID);
		
		if (apptIdx >= 0) {
			var appointments = this.copyObject(this.state.appointments);
			this.setByAccessor(appointments[apptIdx], accessor, value);
			this.mergeState({ appointments: appointments });
		} else {
			this.showAlert("Appointment Not Found", "Appointment " + roleID + " not found.");
		}
	}
	
	onSubmitExisting(roleID, event) {
		var self = this;
		var apptIdx = this.findAppointmentIdx(roleID);
		
		if (apptIdx >= 0) {
			
			// get access requests and stores checkbox
			var access = this.copyObject(this.state.appointments[apptIdx].Access);
			var storesByCheckbox = this.state.appointments[apptIdx].STORES_ACCESS === true;
			
			// see if storeroom was requested by location
			var storesByLocation = false;
			for (var i = 0; i < access.length; i++) {
				if (access[i].LOC_ACCESS_ID === 1) {
					storesByLocation = true;
					break;
				}
			}
			
			// if stores was requested via the checkbox and not by location
			if (storesByCheckbox && !storesByLocation) {
				// add a new access request for storeroom
				access.push({
					id: this.getNewRowID(access),
					ROLE_ID: this.state.appointments[apptIdx].ROLE_ID,
					LOC_ACCESS_ID: 1,
					LOC_ACCESS_NAME: this.getLocationName(1)					
				});
			}
			
			var appointment = {
				AppointmentView: this.copyObject(this.state.appointments[apptIdx]),
				Access: access,
				Keys: this.copyObject(this.state.appointments[apptIdx].Keys),
				Stores: false,
				Bixby: false,
				Change: false,
				NewPerson: false
			}

			// convert dates
			appointment.AppointmentView.BEG_ROLE_DATE = this.dateTimeToMVC(this.getDate(appointment.AppointmentView.BEG_ROLE_DATE));
			appointment.AppointmentView.END_ROLE_DATE = this.dateTimeToMVC(this.getDate(appointment.AppointmentView.END_ROLE_DATE));

			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/BuildingAccess/UpdateAppointment',
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, appointment: appointment }
			}).done(function (data) {
				if (data.Success) {
					self.props.parent.showConfirmation('Appointment changes submitted for approval.');
				} else {
					self.showAlert('Server Error', data.Message);
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			this.showAlert("Appointment Not Found", "Appointment " + roleID + " not found.");
		}
	}
	
	onChangeNew(accessor, value) {
		var appointment = this.copyObject(this.state.newAppointment);
		this.setByAccessor(appointment, accessor, value);
		this.mergeState({ newAppointment: appointment });
	}
	
	onSubmitNew(event) {
		var self = this;
		
		// get access requests and stores checkbox
		var access = this.copyObject(this.state.newAppointment.Access);
		var storesByCheckbox = this.state.newAppointment.STORES_ACCESS === true;
		
		// see if storeroom was also requested by location
		var storesByLocation = false;
		for (var i = 0; i < access.length; i++) {
			if (access[i].LOC_ACCESS_ID === 1) {
				storesByLocation = true;
				break;
			}
		}
		
		// if stores was requested via the checkbox and not location
		if (storesByCheckbox && !storesByLocation) {
			// add a new access request for storeroom
			access.push({
				id: this.getNewRowID(access),
				ROLE_ID: null,
				LOC_ACCESS_ID: 1,
				LOC_ACCESS_NAME: this.getLocationName(1)
			});
		}
		
		var appointment = {
			AppointmentView: this.copyObject(this.state.newAppointment),
			Access: access,
			Keys: this.copyObject(this.state.newAppointment.Keys),
			Stores: false,
			Bixby: this.state.newAppointment.BIXBY_ACCESS === true,
			Change: this.state.newAppointment.CHANGE_FLAG === true,
			NewPerson: this.props.user.personID === null
		};
		
		// convert dates
		appointment.AppointmentView.BEG_ROLE_DATE = this.dateTimeToMVC(this.getDate(appointment.AppointmentView.BEG_ROLE_DATE));
		appointment.AppointmentView.END_ROLE_DATE = this.dateTimeToMVC(this.getDate(appointment.AppointmentView.END_ROLE_DATE));
		
		// this field was given the wrong name
		appointment.AppointmentView.SUPERVISOR = appointment.AppointmentView.SUP_ROLE_ID;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/BuildingAccess/InsertAppointment',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, appointment: appointment, overrideexisting: false }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation('Appointment submitted for approval.');
			} else if (data.AppointmentExists || data.AppointmentRequestExists) {
				var alertTitle = data.AppointmentExists ? "Appointment Exists" : "Appointment Request Exists";
				var alertText = data.AppointmentExists ? "This appointment already exists. Proceed?" :
					"This appointment request already exists. Proceed?";
				self.showOKCancel(alertTitle, alertText, (okClicked) => {
					if (okClicked) self.onSubmitNewOverride(appointment);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onSubmitNewOverride(appointment) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/BuildingAccess/InsertAppointment',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, appointment: appointment, overrideexisting: true }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation('Appointment submitted for approval.');
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	renderAccess(props) {
		// get role ID
		var roleID = this.getByAccessor(props.editor.props.data, 'newAppointmentFlag') ? null : this.getByAccessor(props.editor.props.data, 'ROLE_ID');
		
		// get appointment index
		var apptIdx = this.findAppointmentIdx(roleID);
		
		// get appointment
		var appointment = (apptIdx < 0) ? this.state.newAppointment : this.state.appointments[apptIdx];
		
		return (<Container>
		  <Row>
			<Col sm={9} style={{ marginTop: '-8px' }}>
			  <Form.Control as="select" value={appointment.selectedLocation}
				onChange={(event) => {
					if (apptIdx < 0) {
						// update the new appointment
						var newAppointment = this.copyObject(this.state.newAppointment);
						newAppointment.selectedLocation = event.target.value;
						this.mergeState({ newAppointment: newAppointment });
					} else {
						// update the existing appointment
						var appointments = this.copyObject(this.state.appointments);
						appointments[apptIdx].selectedLocation = event.target.value;
						this.mergeState({ appointments : appointments });
					}
				}}>
				  {this.state.locations.map((option) => {
					  return (
						  <option key={option.LOC_ACCESS_ID} value={option.LOC_ACCESS_ID}>{option.LOC_ACCESS_NAME}</option>
					  );
				  })}
			  </Form.Control>
			</Col>
			<Col sm={3} style={{ marginTop: '-8px' }}>
			  <Button variant='warning' onClick={(event) => { this.addAccessRequest(event, apptIdx) }}>Add</Button>
			</Col>
		  </Row>
		  <Row>
		    <Col sm={12} style={{ marginTop: '8px' }}>
		      <ChemTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_MyAppointments_Access' columns={accessColumns} data={props.value} />
			</Col>
		  </Row>
		</Container>);
	}
	
	renderKeys(props) {
		// get role ID
		var roleID = this.getByAccessor(props.editor.props.data, 'newAppointmentFlag') ? null : this.getByAccessor(props.editor.props.data, 'ROLE_ID');
		
		// get appointment index
		var apptIdx = this.findAppointmentIdx(roleID);
		
		// get appointment
		var appointment = (apptIdx < 0) ? this.state.newAppointment : this.state.appointments[apptIdx];
		
		var rooms;
		
		// if a building has been selected
		if (appointment.selectedBuilding) {
			// get building state ID
			var bldgStateID = this.getBuildingStateID(appointment.selectedBuilding);
		
			// get list of rooms for this building
			rooms = this.state.rooms[bldgStateID];
		} else {
			// no building, no rooms
			rooms = [];
		}
		
		return (<Container>
		  <Row>
			<Col sm={5} style={{ marginTop: '-8px' }}>
			  <Form.Control as="select" value={appointment.selectedBuilding}
				onChange={(event) => {
					// get building state ID
					var bldgStateID = this.getBuildingStateID(event.target.value);
				
					// get list of rooms for this building
					rooms = this.state.rooms[bldgStateID];
					
					// select first room by default
					if (apptIdx < 0) {
						// update new appointment
						var newAppointment = this.copyObject(this.state.newAppointment);
						newAppointment.selectedBuilding = event.target.value;
						newAppointment.selectedRoom = rooms[0].ROOM_ID;
						this.mergeState({ newAppointment : newAppointment });
					} else {
						// update existing appointment
						var appointments = this.copyObject(this.state.appointments);
						appointments[apptIdx].selectedBuilding = event.target.value;
						appointments[apptIdx].selectedRoom = rooms[0].ROOM_ID;
						this.mergeState({ appointments : appointments });
					}
				}}>
				  {this.state.buildings.map((option) => {
					  return (
						  <option key={option.BUILDING_ID} value={option.BUILDING_ID}>{option.BUILDING_NAME}</option>
					  );										
				  })}
			  </Form.Control>
			</Col>
			<Col sm={4} style={{ marginTop: '-8px' }}>
			  <Form.Control as="select" value={appointment.selectedRoom}
				onChange={(event) => {
					if (apptIdx < 0) {
						// update new appointment
						var newAppointment = this.copyObject(this.state.newAppointment);
						newAppointment.selectedRoom = event.target.value;
						this.mergeState({ newAppointment : newAppointment });
					} else {
						// update existing appointment
						var appointments = this.copyObject(this.state.appointments);
						appointments[apptIdx].selectedRoom = event.target.value;
						this.mergeState({ appointments : appointments });
					}
				}}>
				  {rooms.map((option) => {
					  return (
						  <option key={option.ROOM_ID} value={option.ROOM_ID}>{option.ROOM_NAME}</option>
					  );										
				  })}
			  </Form.Control>
			</Col>
			<Col sm={3} style={{ marginTop: '-8px' }}>
			  <Button variant='warning' onClick={(event) => { this.addKeyRequest(event, apptIdx) }}>Add</Button>
			</Col>
		  </Row>
		  <Row>
		    <Col sm={12} style={{ marginTop: '8px' }}>
		      <ChemTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_MyAppointments_Keys' columns={keyColumns} data={props.value} />
			</Col>
		  </Row>
		</Container>);
	}

	renderBixbyAccess(props) {
		if (props.editor.props.data.EXISTING_BIXBY_ACCESS) {
			return <>You have Bixby access<br /></>;
		} else {
			return props.editor.renderFromCellDefinition();
		}
	}
	
	renderSpeedtype(props) {
		if (this.isEmpty(props.editor.props.data.PI_ROLE_ID)) {
			return (<span style={{ fontStyle: 'italic' }}>(Select PI to view speedtypes)</span>);
		} else {
			return props.editor.renderFromCellDefinition();
		}
	}
	
	renderStoresAccess(props) {
		if (props.editor.props.data.EXISTING_STORES_ACCESS) {
			return <>You have Chemistry Stores access<br /></>;
		} else {
			return props.editor.renderFromCellDefinition();
		}
	}
	
	renderWorkTitle(props) {
		if (props.editor.props.data.TYPE_ID && props.editor.props.data.TYPE_ID > 8 && props.editor.props.data.TYPE_ID < 25) {
			return props.editor.doNotRender();
		} else {
			return props.editor.renderFromCellDefinition();
		}
	}
	
	renderExistingAppointments() {
		if (this.state.appointments.length > 0) {
			// prepare text elements for title
			var plural = this.state.appointments.length > 1 ? 's' : '';
			return (
			 <>
			  <Row>
			    <Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state.appointments[0].FNAME} Appointment{plural}</span>
				  <ul>
				    <li>To change your Appointment Type, PI, Supervisor, Unit, or Payroll Title:</li>
					<ol>
					    <li>End current appointment by entering an Appointment End Date</li>
						<li>Create a new appointment using "Add New Appointment" Form</li>
						<li>New appointment requests will need to be approved before being taken into effect</li>
					</ol>
					<li>Keys and electronic access will be granted by your PI, Supervisor, or Delegate upon approval</li>
					<li>Required Field <span style={{ color: 'red' }}>*</span></li>
				  </ul>
				</Col>
			  </Row>
			  {this.state.appointments.map((appointment, index) => {
			    return (
					<Row>
					  <Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
						<ChemEdit parent={this} key={index} columns={columns} data={appointment} user={this.props.user} renderKey={this.state.renderKey}
							onChange={(accessor, value) => this.onChangeExisting(appointment.ROLE_ID, accessor, value)} 
							onSubmit={(event) => this.onSubmitExisting(appointment.ROLE_ID, event)}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={12}>
							  <Button id="update" variant="warning" type="submit" className="float-end">Update</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				);
			  })}
			 </>
			);
		}		
	}
	
	renderPendingRequests() {
		if (this.state.pendingRequests.length > 0) {
			return (
			  <Row>
				<Col>
				  <Row>
					<Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
					  <div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Pending Request Approval</div>
						<ChemTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_MyAppointments_PendingRequests' 
							columns={pendingRequestApproval.columns} data={this.state.pendingRequests} />
					  <div style={{ paddingBottom: '10px' }} />
					</Col>
				  </Row>
				</Col>
			  </Row>
			);
		}
	}

	render() {		
		return (
			<Container fluid>
			  {this.renderExistingAppointments()}
			  {this.renderPendingRequests()}
			  <Row>
			    <Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Add New Appointment</span>
				  <ChemEdit parent={this} renderKey={this.state.renderKey} columns={newColumns} data={this.state.newAppointment} user={this.props.user}
					onChange={(accessor, value) => this.onChangeNew(accessor, value)} onSubmit={(event) => this.onSubmitNew(event)}>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Update</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			</Container>
		);
	}
}

const columns = [
	{ Header: 'Appointment ID', accessor: 'ROLE_ID', editable: false },
	{ Header: 'Appointment Type', accessor: 'TYPE_NAME', editable: false },
	{ Header: 'PI/Faculty Sponsor/Unit Manager', accessor: 'PI_NAME', editable: false },
	{ Header: 'Functional Supervisor', accessor: 'SUP_NAME', editable: false },
	{ Header: 'Unit', accessor: 'UNIT_NAME', editable: false },
	{ Header: 'Payroll Title', accessor: 'TITLE_NAME', editable: false },
	{ Header: 'Directory Work Title', accessor: 'WORK_TITLE_ID', type: 'select', Cell: props => props.parent.renderWorkTitle(props),
		options: { value: 'WORK_TITLE_ID', label: 'WORK_TITLE_NAME', entity: 'WORK_TITLE', order: 'WORK_TITLE_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Appointment Start Date',	accessor: 'BEG_ROLE_DATE', type: 'date' },
	{ Header: 'Appointment End Date', accessor: 'END_ROLE_DATE', type: 'date',
      subscript: '<span style="font-size: 12px; font-style: italic">(MM/DD/YY)<br /><u>NOTE</u>: Entering a date will request immediate end of appointment.</span>'	},
	{ Header: 'Default Speedtype', accessor: 'SPEEDTYPE', type: 'select', Cell: props => props.parent.renderSpeedtype(props),
		options: { value: 'SPEEDTYPE', label: 'SPEEDTYPE_INFO', entity: 'ROLE_SPEEDTYPES_VW', order: 'SPEEDTYPE_INFO',
			search: { Operator: 'or', Children: [
				{ Attribute: 'ROLE_ID', Operator: '=', LongAccessor: 'PI_ROLE_ID' },
				{ Attribute: 'ROLE_ID', Operator: '=', LongAccessor: 'ROLE_ID' }
			]}}},
	{ Header: 'Primary Phone', accessor: 'PHONE1' },
	{ Header: 'Secondary Phone', accessor: 'PHONE2' },
	{ Header: 'Campus Office Location', accessor: 'ROOM_ID', type: 'select',
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: 'LOCATION' }},
	{ Header: 'Campus Lab Room', accessor: 'LAB_LOCATION' },
	{ Header: 'Campus Lab Building', accessor: 'LAB_BUILDING', type: 'select',
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDINGS_VW', order: 'BUILDING_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'CHEMISTRY_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' }
			]}
		}
	},
	{ Header: 'Alternate Room', accessor: 'ALT_LOCATION' },
	{ Header: 'Alternate Building', accessor: 'ALT_BUILDING', type: 'select',
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDINGS_VW', order: 'BUILDING_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Off Campus Location', accessor: 'OUTSIDE_LOCATION' },
	{ Header: 'Keys', accessor: 'Keys', Cell: props => props.parent.renderKeys(props) },
	{ Header: 'Access', accessor: 'Access', Cell: props => props.parent.renderAccess(props) },
	{ Header: 'Chemistry Storeroom Access', accessor: 'STORES_ACCESS', type: 'checkbox', label: 'YES', Cell: props => props.parent.renderStoresAccess(props),
	  subscript: '<span style="font-size: 12px; font-style: italic">Mandatory safety certification is required for access to the student workshop. After approval by your PI/Supervisor/Delegate, you will receive instructions for scheduling your safety certification training.</span>' },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', subscript: '<span style="font-size: 12px; font-style: italic">250 Characters Max</span>' },
	{ Header: 'Modified Date', accessor: 'MODIFIED', editable: false }
];

const pendingRequestApproval = {
	query: { entity: 'PENDING_REQUEST_APPROVAL_VW', search: { Attribute: 'REQ_ID', Operator: '=' }},
	columns: [
		{ Header: '', accessor: 'REQ_ROLE_ID', 
			Cell: props => { if (props.row.values.REQ_STATUS === 'R') return (<Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteRequest(props.value)} />); else return ''; }, width: 25 },
		{ Header: 'Appointment', accessor: 'TYPE_NAME' },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'To Be Approved By', accessor: 'EMAIL_SENT' },
		{ Header: 'Speedtype', accessor: 'SPEEDTYPE' },
		{ Header: 'Appt. Date', accessor: 'APPT_DATE' },
		{ Header: 'Requested', accessor: 'REQUESTED' },
		{ Header: 'Status', accessor: 'REQ_STATUS' }
	]
};

const newColumns = [
	{ Header: 'Appointment Type', accessor: 'TYPE_ID', type: 'select', required: true,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'ROLE_TYPES', order: 'TYPE_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'TYPE_ID', Operator: 'not in', LongList: [4, 5, 6, 8, 28] }
			]}
		}
	},
	{ Header: 'PI/Faculty&nbsp;Sponsor/Unit&nbsp;Manager', accessor: 'PI_ROLE_ID', type: 'select', required: true,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'PI_VW', order: 'PI_NAME' }},
	{ Header: 'Functional Supervisor', accessor: 'SUP_ROLE_ID', type: 'select',
		options: { value: 'ROLE_ID', label: 'SUP_NAME', entity: 'SUPERVISORS_VW', order: 'SUP_NAME' }},
	{ Header: 'Payroll Title', accessor: 'TITLE_ID', type: 'select',
		options: { value: 'TITLE_ID', label: 'TITLE_NAME', entity: 'PAYROLL_TITLE', order: 'TITLE_NAME',
		search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Appointment Start Date', accessor: 'BEG_DATE', type: 'date', required: true, subscript: '<span style="font-size: 12px; font-style: italic">(MM/DD/YY)</span>' },
	{ Header: 'Default Speedtype', accessor: 'SPEEDTYPE', type: 'select', Cell: props => props.parent.renderSpeedtype(props),
		options: { value: 'SPEEDTYPE', label: 'SPEEDTYPE_INFO', entity: 'ROLE_SPEEDTYPES_VW', order: 'SPEEDTYPE_INFO',
			search: { Attribute: 'ROLE_ID', Operator: '=', LongAccessor: 'PI_ROLE_ID' }}},
	{ Header: 'Primary Phone', accessor: 'PHONE1' },
	{ Header: 'Secondary Phone', accessor: 'PHONE2' },
	{ Header: 'Campus Office Location', accessor: 'ROOM_ID', type: 'select',
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: 'LOCATION' }},
	{ Header: 'Campus Lab Room', accessor: 'LAB_LOCATION' },
	{ Header: 'Campus Lab Building', accessor: 'LAB_BUILDING', type: 'select',
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDINGS_VW', order: 'BUILDING_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'CHEMISTRY_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' }
			]}
		}
	},
	{ Header: 'Alternate Room', accessor: 'ALT_LOCATION' },
	{ Header: 'Alternate Building', accessor: 'ALT_BUILDING', type: 'select',
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDINGS_VW', order: 'BUILDING_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Off Campus Location', accessor: 'OUTSIDE_LOCATION' },
	{ Header: 'Keys', accessor: 'Keys', Cell: props => props.parent.renderKeys(props) },
	{ Header: 'Access', accessor: 'Access', Cell: props => props.parent.renderAccess(props) },
	{ Header: 'Chemistry Storeroom Access', accessor: 'STORES_ACCESS', type: 'checkbox', label: 'YES', Cell: props => props.parent.renderStoresAccess(props),
	  subscript: '<span style="font-size: 12px; font-style: italic">Mandatory safety certification is required for access to the student workshop. After approval by your PI/Supervisor/Delegate, you will receive instructions for scheduling your safety certification training.</span>' },
	{ Header: 'Bixby After Hour Access', accessor: 'BIXBY_ACCESS', type: 'checkbox', label: 'YES', Cell: props => props.parent.renderBixbyAccess(props),
      subscript: '<span style="font-size: 12px; font-style: italic">Request for Bixby after hours access for undergraduates.<br/>Make sure <u>Shamaya Pellum</u> is selected as PI</span>' },
	{ Header: 'Comments', accessor: 'ROLE_COMMENTS', type: 'textarea', subscript: '<span style="font-size: 12px; font-style: italic">250 Characters Max</span>' }
];

const keyColumns = [
	{ Header: '', accessor: 'id', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteKeyRequest(props)} />, width: 25 },
	{ Header: 'Building', accessor: 'BUILDING_NAME' },
	{ Header: 'Room', accessor: 'ROOM_NAME' },
	{ Header: 'BUILDING_ID', accessor: 'BUILDING_ID', show: false },
	{ Header: 'ROOM_ID', accessor: 'ROOM_ID', show: false },
	{ Header: 'ROLE_ID', accessor: 'ROLE_ID', show: false }
];

const accessColumns = [
	{ Header: '', accessor: 'id', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteAccessRequest(props)} />, width: 25 },
	{ Header: 'Location', accessor: 'LOC_ACCESS_NAME' },
	{ Header: 'LOC_ACCESS_ID', accessor: 'LOC_ACCESS_ID', show: false },
	{ Header: 'ROLE_ID', accessor: 'ROLE_ID', show: false }
];

export default MyAppointments;