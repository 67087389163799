import React from 'react';
import ChemComponent from './ChemComponent';
import ChemTable from "./ChemTable";
import { CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

class QueryTable extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			data: [],
			renderKey: 0,
			pageSize: (props.table.pageSize === undefined || props.table.pageSize === null) ? -1: props.table.pageSize,
			pageNumber: (props.table.pageSize === undefined || props.table.pageSize === null) ? -1 : 1,
			pageCount: 1
		};
	}
	
	qtajax(props, callback) {
		var self = this;
		
		var user = this.getUser();
		
		if (user) {
			var data = this.mergeObject(props.data, { __RequestVerificationToken: user.antiForgeryToken });

			this.ajax({
				type: 'post',
				url: this.getConfig().host + props.url,
				data: data
			}).done(function (data) {
				if (data.Success) {
					// for each row in the data
					for (var i = 0; i < data[props.accessor].length; i++) {
						// if there is not already an id column, add one
						if (data[props.accessor][i].id === undefined) data[props.accessor][i].id = i;
						// if there is not already a drag column, add one
						if (data[props.accessor][i].drag === undefined) data[props.accessor][i].drag = i;
					}
					
					if (callback) callback(data);
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	componentDidMount() {
		this.getData(this.state.pageNumber, this.state.pageSize);
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.renderKey !== this.props.renderKey) this.getData(this.state.pageNumber, this.state.pageSize);
	}
	
	getData(pageNumber, pageSize) {
		if (!this.props.table.hold) {
			var self = this;
			// if this is an ajax query
			if (this.props.table.ajax) {
				var ajax = this.mergeObject(this.props.table.ajax, {
					data: {
						pageNumber: pageNumber,
						pageSize: pageSize
					}
				});
				
				this.qtajax(ajax, (ajaxResult) => {
					self.mergeState({
						data: ajaxResult[this.props.table.ajax.accessor],
						pageSize: ajaxResult.PageSize,
						pageNumber: ajaxResult.PageNumber,
						pageCount: ajaxResult.PageCount,
						renderKey: self.state.renderKey + 1
					}, () => {
						if (self.props.onResult) self.props.onResult(ajaxResult);
					});
				});
			} else {
				this.search({
					query: this.props.table.query,
					pageSize: pageSize,
					pageNumber: pageNumber
				}, (searchResult) => {
					self.mergeState({
						data: searchResult.Data,
						pageSize: searchResult.PageSize,
						pageNumber: searchResult.PageNumber,
						pageCount: searchResult.PageCount,
						renderKey: self.state.renderKey + 1
					}, () => {
						if (self.props.onResult) self.props.onResult(searchResult);
					});
				});
			}
		}
	}
	
	firstPage() {
		if (this.state.pageNumber !== 1) {
			this.getData(1, this.state.pageSize);
		}
	}
	
	previousPage() {
		if (this.state.pageNumber > 1) {
			this.getData(this.state.pageNumber - 1, this.state.pageSize);
		}		
	}
	
	nextPage() {
		// server will correct if page number is too big
		this.getData(this.state.pageNumber + 1, this.state.pageSize);		
	}
	
	lastPage() {
		// use -1 to indicate last page since we don't 
		// necessarily know how many pages there are now
		this.getData(-1, this.state.pageSize);
	}
	
	setPageSize(pageSize) {
		if (pageSize !== this.state.pageSize) {
			this.getData(this.state.pageNumber, pageSize);
		}
	}
	
	setPageNumber(pageNumber) {
		if (pageNumber !== this.state.pageNumber) {
			this.getData(pageNumber, this.state.pageSize);
		}
	}

	findSortIdx(order, accessor) {
		for (var i = 0; i < order.length; i++) {
			if (order[i] === accessor || order[i] === accessor + ' desc') return i;
		}
		
		return -1;
	}

	setSortArrow(columns, colIdx, ascending, sortIdx) {
		var originalHeader = columns[colIdx].Header;
		if (ascending) {
			columns[colIdx].Header = props => <>{sortIdx >= 0 ? <span style={{ fontWeight: 'bold', fontSize: '8pt' }}>{sortIdx + 1}</span> : ''}<CaretUpFill />{originalHeader}</>;
		} else {
			columns[colIdx].Header = props => <>{sortIdx >= 0 ? <span style={{ fontWeight: 'bold', fontSize: '8pt' }}>{sortIdx + 1}</span> : ''}<CaretDownFill />{originalHeader}</>;
		}
	}
	
	onClickHeader(event, accessor) {
		if (this.props.onTableSort && this.isSortable(accessor)) {
			var order = this.getTableOrder();
			
			if (event.ctrlKey) {
				// if we are clicking on a column that's already in the sort
				var sortIdx = this.findSortIdx(order, accessor);
				if (sortIdx >= 0) {
					// toggle the sort
					if (order[sortIdx].endsWith(' desc')) {
						order[sortIdx] = order[sortIdx].substring(0, order[sortIdx].length - 5);
					} else {
						order[sortIdx] = order[sortIdx] + ' desc';
					}
				} else {
					// add selected column to end of sort
					order.push(accessor);
				}
			} else {
				// if there is only one sort, and it's the column we clicked
				if (order.length === 1 && order[0] === accessor) {
					// toggle the direction of the sort
					if (order[0].endsWith(' desc')) {
						order[0] = order[0].substring(0, order[0].length - 5);
					} else {
						order[0] = order[0] + ' desc';
					}
				} else {
					// throw away current sort and start with selected column ascending
					order = [accessor];
				}
			}
			
			this.props.onTableSort(order);
		}
	}
	
	isSortable(accessor) {
		var colIdx = this.findColumnByAccessor(this.props.table.columns, accessor);
		if (colIdx === -1) return false;
		// default is 'true', so if the attribute is missing then the column is sortable
		if (this.props.table.columns[colIdx].sortable === undefined || this.props.table.columns[colIdx].sortable === null) return true;
		return this.props.table.columns[colIdx].sortable;
	}
	
	getTableOrder() {
		var i;
		var order = [];
		if (this.props.table.query && this.props.table.query.order) {
			if (Array.isArray(this.props.table.query.order)) {
				for (i = 0; i < this.props.table.query.order.length; i++) {
					order.push(this.props.table.query.order[i]);
				}
			} else {
				order.push(this.props.table.query.order);
			}
		} else if (this.props.order) {
			if (Array.isArray(this.props.order)) {
				for (i = 0; i < this.props.order.length; i++) {
					order.push(this.props.order[i]);
				}
			} else {
				order.push(this.props.order);
			}			
		}
		return order;
	}

	render() {
		// modify column headers to reflect ordering
		var order = this.getTableOrder();
		var columns = this.copyColumns(this.props.table.columns);
		for (var colIdx = 0; colIdx < columns.length; colIdx++) {
			var sortIdx = this.findSortIdx(order, columns[colIdx].accessor);
			if (sortIdx >= 0) this.setSortArrow(columns, colIdx, !order[sortIdx].endsWith(' desc'), order.length > 1 ? sortIdx : -1);
		}
		
		if (this.props.paging === false) {
			return (
				<ChemTable renderKey={this.state.renderKey} parent={this.props.parent} name={this.props.name}
					columns={columns} data={this.state.data} offsetHeight={this.props.offsetHeight}
					rowSelected={(row) => { if (this.props.rowSelected) this.props.rowSelected(row); }}
					onClickHeader={(event, accessor) => this.onClickHeader(event, accessor)} paging={false} />
			);
		} else {
			return (
				<ChemTable renderKey={this.state.renderKey} parent={this.props.parent} name={this.props.name}
					columns={columns} data={this.state.data} offsetHeight={this.props.offsetHeight}
					pageSize={this.state.pageSize} pageNumber={this.state.pageNumber} pageCount={this.state.pageCount}
					firstPage={() => this.firstPage()} previousPage={() => this.previousPage()} nextPage={() => this.nextPage()} 
					lastPage={() => this.lastPage()} setPageSize={(pageSize) => this.setPageSize(pageSize)}
					setPageNumber={(pageNumber) => this.setPageNumber(pageNumber)} rowSelected={(row) => { if (this.props.rowSelected) this.props.rowSelected(row); }}
					onClickHeader={(event, accessor) => this.onClickHeader(event, accessor)} />
			);
		}
	}
}

export default QueryTable
