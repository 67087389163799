import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Link } from 'react-router-dom';

class Group extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			group: {
				ID: '',
				NAME: '',
				PI: null,
				FSA: null,
				FM1: null,
				FM2: null
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadGroup();
	}

	loadGroup(event) {
		var self = this;

		if (this.props.params.groupId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/FAS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'PIGROUP',
					search: { Attribute: 'ID', Operator: '=', LongValue: this.props.params.groupId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						self.mergeState({
							group: data.Data[0],
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Group Error', 'Expected to find 1 group for ID ' + 
							self.props.params.groupId + ' but found ' + data.Data.length);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			this.mergeState({
				group: {
					ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var group = this.copyObject(this.state.group);
		this.setByAccessor(group, accessor, value);
		this.mergeState({ group: group, renderKey: this.state.renderKey + 1 });
	}
	
	validateGroup(event, columns, data) {
		return this.validate(columns, data);
	}

	onSubmit(event) {
		var self = this;
		var group = this.copyObject(this.state.group);
		
		// add sequence name if this is a new group
		if (!this.props.params.groupId)
		{
			group.ID = 'pigroup_id_seq.nextval';
		}

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/FAS/UpdateGroup',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				group: group
			}
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					var verb = self.props.params.groupId ? 'updated' : 'added';
					var groupId = self.props.params.groupId || data.ID;
					return (<>
						Group <Link to={'/FAS/Groups/' + groupId}>{groupId}</Link> {verb}.
					</>);
				});				
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
						  Group {this.state.group.ID}
						</div>
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
						<ChemEdit parent={this} user={this.props.user} columns={groupColumns} data={this.state.group}
							onChange={(accessor, value) => this.onChange(accessor, value)} renderKey={this.state.renderKey}
							validate={(event, columns, data) => this.validateGroup(event, columns, data)}
							onSubmit={(event) => this.onSubmit(event)}>
							<Form.Group as={Row}>
							  <Col style={{ borderTop: '1px solid #eee', paddingTop: '5px' }}>
								<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
							  </Col>
							</Form.Group>							
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const groupColumns = [
	{ Header: 'Group Name', accessor: 'NAME', maxlength: 45, required: true },
	{ Header: 'PI', accessor: 'PI', type: 'select', required: true,
		options: { value: 'ID', label: 'NAME', entity: 'FAS.GROUP_ADMIN_SELECT_VW', order: 'NAME',
			search: { Attribute: 'ADMINLEVEL', Operator: '=', LongValue: 6 }}},
	{ Header: 'Facility Support Admin', accessor: 'FSA', type: 'select', required: true,
		options: { value: 'ID', label: 'NAME', entity: 'FAS.GROUP_ADMIN_SELECT_VW', order: 'NAME',
			search: { Attribute: 'ADMINLEVEL', Operator: '=', LongValue: 3 }}},
	{ Header: 'Research Administrator 1', accessor: 'FM1', type: 'select', required: true,
		options: { value: 'ID', label: 'NAME', entity: 'FAS.GROUP_ADMIN_SELECT_VW', order: 'NAME',
			search: { Attribute: 'ADMINLEVEL', Operator: '=', LongValue: 4 }}},
	{ Header: 'Research Administrator 2', accessor: 'FM2', type: 'select', required: true,
		options: { value: 'ID', label: 'NAME', entity: 'FAS.GROUP_ADMIN_SELECT_VW', order: 'NAME',
			search: { Attribute: 'ADMINLEVEL', Operator: '=', LongValue: 4 }}},
];

export default withRouter(Group);
