import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare } from 'react-bootstrap-icons';

class Requests extends ChemComponent {
	constructor(props) {
		super(props);
		
		// start with last 60 days
		var from60Days = new Date();
		from60Days.setDate(from60Days.getDate() - 60);

		var search = {
			REQUESTDATE_FROM: this.dateTimeToMVC(from60Days),
			REQUESTDATE_TO: null,
			STATUS: ['Saved','Submitted','Updated','Completed']
		};
		
		var requestListInit = this.copyTable(requestList);
		requestListInit.query.search = this.constructSearch(search);

		this.state = {
			requestList: requestListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.REQUESTDATE_FROM)) search.Children.push({ Attribute: 'REQUESTDATE', Operator: '>=', DateValue: data.REQUESTDATE_FROM });
		if (!this.isEmpty(data.REQUESTDATE_TO)) search.Children.push({ Attribute: 'REQUESTDATE', Operator: '<', DateValue: this.nextDayMVC(data.REQUESTDATE_TO) });
		if (!this.isEmpty(data.STATUS)) search.Children.push({ Attribute: 'STATUS', Operator: 'in', StringList: data.STATUS });
		
		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}

	onSubmitSearch(event) {
		var newRequestList = this.copyTable(requestList);
		newRequestList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			requestList: newRequestList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	editRequest(facilitiesId, requestId) {
		if (facilitiesId === 34) {
			this.props.navigate('/FAS/Microrequests/' + requestId);
		} else if (facilitiesId === 33) {
			this.props.navigate('/FAS/Xrayrequests/' + requestId);
		}
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Requests',
			columns: exportColumns,
			entity: 'FAS.REQUESTS_VW',
			search: this.constructSearch(this.state.search),
			order: 'REQUESTDATE desc'
		});			
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Requests</div>
						<ChemEdit parent={this} user={this.props.user} columns={requestSearch} data={this.state.search}
							renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="search" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='FAS_Requests' table={this.state.requestList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='260px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const requestSearch = [
	{ Header: 'Request Date From', accessor: 'REQUESTDATE_FROM', type: 'date', row: 0, col: 0 },
	{ Header: 'Request Date To', accessor: 'REQUESTDATE_TO', type: 'date', row: 0, col: 1 },
	{ Header: 'Status', accessor: 'STATUS', type: 'list', row: 0, col: 2,
		options: [
			{ value: 'Saved', label: 'Saved' },
			{ value: 'Submitted', label: 'Submitted' },
			{ value: 'Updated', label: 'Updated' },
			{ value: 'Completed', label: 'Completed' },
			{ value: 'Archived', label: 'Archived' }
		]
	}
];

const requestList = {
	query: { entity: 'FAS.REQUESTS_VW', search: null, order: 'REQUESTDATE desc' },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'FACILITIESID', show: false },
		{ Header: '', accessor: 'ID', width: 25,
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} 
				onClick={() => props.parent.editRequest(props.row.values.FACILITIESID, props.value)} /> },
		{ Header: 'Request No.', accessor: 'IDDISPLAY' },
		{ Header: 'Requester Name', accessor: 'NAME' },
		{ Header: 'Facility', accessor: 'CODE' },
		{ Header: 'Service', accessor: 'SERVICE' },
		{ Header: 'Status', accessor: 'STATUS' },
		{ Header: 'Billing Date', accessor: 'BILLINGDATEDISPLAY' }
	]
};

const exportColumns = [
	{ Header: 'Request Date', accessor: 'APPDATEDISPLAY', width: 22 },
	{ Header: 'Last Name', accessor: 'LASTNAME', width: 40 },
	{ Header: 'First Name', accessor: 'FIRSTNAME', width: 40 },
	{ Header: 'Email', accessor: 'USERNAME', width: 40 },
	{ Header: 'PI?', accessor: 'AREYOUPIDISPLAY', width: 8 },
	{ Header: 'Group', accessor: 'PIGRPNAME', width: 40 },
	{ Header: 'Grant Exp Date', accessor: 'GRANTEXPDATEDISPLAY', width: 23 },
	{ Header: 'Status', accessor: 'STATUS', width: 25 },
];

export default withRouter(Requests);
