import React from 'react';
import { withRouter } from './withRouter';
import ChemComponent from './ChemComponent';
import { PlusCircle, DashCircle } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form'

class TreeMenu extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = this.createStateFromProps(props);
	}
	
	componentDidUpdate(prevProps) {
		// if menu items have changed
		if (!this.objectsAreEqual(prevProps.menuItems, this.props.menuItems)) {
			// rebuild state from scratch
			this.setState(this.createStateFromProps(this.props));
		} else if (prevProps.selected !== this.props.selected ||
			prevProps.selectedRoute !== this.props.selectedRoute) {
			// only update selected/selectedRoute
			var selected;
			var selectedRoute;
			
			if (this.props.selected !== undefined && this.props.selected !== null) {
				selected = this.props.selected;
			} else {
				selected = this.state.selected;
			}
			
			if (this.props.selectedRoute !== undefined && this.props.selectedRoute !== null) {
				selectedRoute = this.props.selectedRoute;
			} else {
				selectedRoute = this.state.selectedRoute;
			}
			
			if (selected !== this.state.selected || selectedRoute !== this.state.selectedRoute) {
				this.mergeState({
					selected: selected,
					selectedRoute: selectedRoute
				});
			}
		}
	}
	
	createStateFromProps(props) {
		var nodeOpen = [];
		this.formRefs = [];
		for (var i = 0; i < props.menuItems.length; i++) {
			nodeOpen.push(false);
			if (props.menuItems[i].action) {
				this.formRefs.push(React.createRef());
			} else {
				this.formRefs.push(null);
			}
		}
		
		// create fake state in case there isn't one yet
		var state;
		if (this.state) {
			state = {
				selected: this.state.selected,
				selectedRoute: this.state.selectedRoute
			};
		} else {
			state = {
				selected: props.selected || -1,
				selectedRoute: props.selectedRoute
			};
		}

		return {
			nodeOpen: nodeOpen,
			selectionKeys: props.isSubMenu ? props.selectionKeys : this.createSelectionKeyTree(props.menuItems, 0),
			level: props.isSubMenu ? props.level : 0,
			selected: props.selected || state.selected,
			selectedRoute: props.selectedRoute || state.selectedRoute
		};
	}
	
	getSelectionKey(menuItems, startIdx, route) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				var selectionKey = this.getSelectionKey(menuItems[i].nodes, startIdx, route);
				if (selectionKey !== -1) return selectionKey;
				startIdx += this.countNodes(menuItems[i].nodes);
			} else {
				if (menuItems[i].route && route && menuItems[i].route.toUpperCase() === route.toUpperCase()) return startIdx;
				startIdx++;
			}
		}
		
		return -1;
	}

	createSelectionKeyTree(menuItems, startIdx) {
		var selectionKeyTree = [];
		
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				var subtree = this.createSelectionKeyTree(menuItems[i].nodes, startIdx);
				selectionKeyTree.push(subtree);
				startIdx += this.countNodes(subtree);
			} else {
				var key = startIdx++;
				selectionKeyTree.push({ key: key });
			}
		}
		
		return selectionKeyTree;
	}
	
	countNodes(tree) {
		if (Array.isArray(tree)) {
			var nodeCount = 0;
			for (var i = 0; i < tree.length; i++) {
				nodeCount += this.countNodes(tree[i]);
			}
			return nodeCount;
		} else {
			return 1;
		}
	}
	
	selectMenuItem(menuItem, key) {
		var self = this;
		
		if (this.props.isSubMenu) {
			this.props.parent.selectMenuItem(menuItem, key);
		} else {
			this.mergeState({
				selected: key,
				selectedRoute: null
			}, () => {
				if (menuItem.url) {
					window.open(menuItem.url, menuItem.target).focus();
				} else if (menuItem.onClick) {
					menuItem.onClick({ parent: self.getNonTreeMenuParent() });
				} else if (menuItem.route) {
					self.props.navigate(menuItem.route);
				}
			});
		}
	}
	
	getNonTreeMenuParent() {
		if (this.props.isSubMenu) {
			return this.props.parent.getNonTreeMenuParent();
		} else {
			return this.props.parent;
		}
	}

	clickMenu(menuItem, index, key) {
		if (menuItem.nodes) {
			var newNodeOpen = [];

			for (var i = 0; i < this.state.nodeOpen.length; i++) {
				newNodeOpen.push(i === index ? !this.state.nodeOpen[i] : this.state.nodeOpen[i]);
			}
			this.mergeState({
				nodeOpen: newNodeOpen
			});
		} else {
			this.selectMenuItem(menuItem, key);
		}
	}
	
	calculateSelected() {
		return (!this.props.isSubMenu && this.state.selectedRoute) ? this.getSelectionKey(this.props.menuItems, 0, this.state.selectedRoute) : this.state.selected;
	}
	
	renderLabel(menuItem, index) {
		// use bold style if the menu item is selected
		var style = {}
		if (index >= 0 && index < this.state.selectionKeys.length && this.calculateSelected() === this.state.selectionKeys[index].key) {
			style.fontWeight = 'bold';
		}
		
		// if this is a form
		if (menuItem.action) {
			var dataKeys = Object.keys(menuItem.data);
			return (<Form ref={this.formRefs[index]} action={menuItem.action} method='POST'>
				{dataKeys.map((dataKey, index) => {
					return(<input key={index} type='hidden' name={dataKey} value={menuItem.data[dataKey]} />);
				})}
				<span style={style}>{menuItem.label}</span>
			</Form>);
		} else {
			return (<span style={style}>{menuItem.label}</span>);
		}
	}

	render() {
	  var divStyle = {
		  backgroundImage: 'linear-gradient(rgb(237, 231, 0), rgb(253, 181, 21))',
		  padding: '5px 10px',
		  cursor: 'pointer'
	  };
	  
	  if (this.state.level > 0) {
		  divStyle.borderLeft = '' + (2 * this.state.level) + 'px solid #000';
	  }
	  
	  return (<>
		{this.props.menuItems.map((menuItem, index) => (<div key={index}>
			<div style={divStyle} onClick={() => {
				if (this.formRefs[index]) {
					this.formRefs[index].current.submit();
				} else {
					this.clickMenu(menuItem, index, this.state.selectionKeys[index].key);
				}
			}}>
				<div style={{ float: 'right' }}>
					{!this.state.nodeOpen[index] && menuItem.nodes && <PlusCircle />}
					{this.state.nodeOpen[index] && menuItem.nodes && <DashCircle />}
				</div>
				{this.renderLabel(menuItem, index)}
			</div>
			{this.state.nodeOpen[index] && menuItem.nodes && 
				<TreeMenu parent={this} isSubMenu={true} menuItems={menuItem.nodes} level={this.state.level + 1} selectionKeys={this.state.selectionKeys[index]} selected={this.calculateSelected()} />}
		</div>))}
	  </>);
	}
}

export default withRouter(TreeMenu);