import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';

class Home extends ChemComponent {	
	constructor(props) {
		super(props);

		this.state = {
			login: {
				ACTION: null,
				AFFILIATION: null,
				LAB: null,
				USERNAME: '',
				PASSWORD: ''
			},
			loginColumns: this.filterColumns(loginColumns),
			piSelect: [],
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/GetFASPISelectView'
		}).done(function (data) {
			if (data.Success) {
				// create array for pi select options
				var piSelect = [
					{
						value: null,
						label: 'Select...'
					}
				];
				
				// for each result in the data
				for (var i = 0; i < data.Data.length; i++) {
					piSelect.push({
						value: data.Data[i].NAME,
						label: self.copyObject(data.Data[i].NAME)
					});
				}
				
				// copy current login columns
				var newLoginColumns = self.copyObject(self.state.loginColumns);
			
				// find PIGRPNAME column
				var piIdx = self.findColumnByAccessor(newLoginColumns, 'PIGRPNAME');
				
				// if column was found
				if (piIdx >= 0) {
					// set select options to pi group list
					newLoginColumns[piIdx].options = self.copyObject(piSelect);
				}
			
				self.mergeState({
					loginColumns: newLoginColumns,
					piSelect: piSelect
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	onChange(accessor, value) {
		var login = this.copyObject(this.state.login);
		this.setByAccessor(login, accessor, value);
		
		var filter = null;
		
		if (login.ACTION === 'APPLY') {
			filter = login.AFFILIATION || 'APPLY';
		} else if (['USER','ADMIN'].includes(login.ACTION)) {
			filter = 'LOGIN';
		}
		
		this.setState({
			login: login,
			loginColumns: this.filterColumns(loginColumns, filter),
			renderKey: this.state.renderKey + 1
		});
	}
	
	filterColumns(columns, filter) {
		var newColumns = [];
		
		for (var i = 0; i < columns.length; i++) {
			if (!columns[i].includedby ||
				(Array.isArray(columns[i].includedby) && columns[i].includedby.includes(filter)) ||
				(columns[i].includedby && columns[i].includedby === filter)) {
				// make a copy of the column
				var newColumn = this.copyObject(columns[i]);
				
				// if this is the PIGRPNAME column
				if (newColumn.accessor === 'PIGRPNAME') {
					// copy pi list to select options
					newColumn.options = this.copyObject(this.state.piSelect);
				}
				
				// add to new list of columns
				newColumns.push(newColumn);
			}
		}

		return newColumns;
	}
	
	onSubmit(event) {
		var self = this;
		if (this.state.login.ACTION === 'APPLY') {
			this.submitApplication();
		} else {
			this.props.logIn(this.state.login.ACTION, this.state.login.USERNAME, this.state.login.PASSWORD, () => {
				if (self.state.login.ACTION === 'USER') {
					this.props.navigate('/FAS/UserHome');
				} else if (self.state.login.ACTION === 'ADMIN') {
					this.props.navigate('/FAS/AdminHome');
				}
			});
		}
	}
	
	submitApplication() {
		var self = this;
		
		// interpret inputs as necessary
		var application = this.copyObject(this.state.login);
		application.USERNAME = application.EMAIL;
		if (application.PIGRPNAME === 'I am the PI') {
			application.PIGRPNAME = application.LASTNAME;
			application.AREYOUPI = 1;
		} else {
			application.AREYOUPI = 0;
		}

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/SubmitFASApplication',
			data: {
				application: application
			}
		}).done(function (data) {
			if (data.Success) {
				self.showConfirmation(() => {
					return (<>
						Your application has been received.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}

	render() {
		return(
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>MicroAnalytical Lab Requests</div>
				  <ChemEdit parent={this} user={this.props.user} columns={this.state.loginColumns} data={this.state.login}
					renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)}
					onSubmit={(event) => this.onSubmit(event)}>
					{!this.isEmpty(this.state.login.ACTION) && <Form.Group as={Row}>
					  <Col>
						<Button id="save" variant="warning" type="submit" className="float-end">{this.state.login.ACTION === 'APPLY' ? 'Apply' : 'Log In'}</Button>
					  </Col>
					</Form.Group>}
				  </ChemEdit>	
				</Col>
			  </Row>
			</Container>
		);
	}
}

const loginColumns = [
	{ Header: 'Action', accessor: 'ACTION', type: 'select',
		options: [{ value: null, label: 'Select...' },{ value: 'APPLY', label: 'Apply for Access' },
			{ value: 'USER', label: 'Login as User' },{ value: 'ADMIN', label: 'Login as Admin' }] },
	{ Header: 'Affiliation', accessor: 'AFFILIATION', includedby: ['APPLY','chem','affil','non'], type: 'select',
		options: [{ value: null, label: 'Select...' },{ value: 'chem', label: 'College of Chemistry Users' },
			{ value: 'affil', label: 'UCB or UC Affiliate Users' },{ value: 'non', label: 'Non-UC Users' }] },
	{ Header: 'Lab', accessor: 'FACILITYID', includedby: ['APPLY','chem','affil','non'], type: 'select',
		options: [{ value: null, label: 'Select...' },{ value: 34, label: 'Microanalytical Facility' },
			{ value: 33, label: 'X-Ray Crystallography' }]}, 
	{ Header: 'Username', accessor: 'USERNAME', includedby: ['LOGIN'] },
	{ Header: 'Password', accessor: 'PASSWORD', includedby: ['LOGIN'], type: 'password' },
	{ Header: 'First Name', accessor: 'FIRSTNAME', includedby: ['chem','affil','non'], required: true },
	{ Header: 'Middle Initial', accessor: 'MIDDLENAME', includedby: ['chem','affil','non'] },
	{ Header: 'Last Name', accessor: 'LASTNAME', includedby: ['chem','affil','non'], required: true },
	{ Header: 'Phone', accessor: 'PHONE', includedby: ['chem','affil','non'], required: true },
	{ Header: 'Email', accessor: 'EMAIL', includedby: ['chem','affil','non'], required: true },
	{ Header: 'Office Room and Building', accessor: 'ROOMBUILD', includedby: 'chem', required: true },
	{ Header: 'Campus/Work Address', accessor: 'ADDRESS', includedby: ['affil','non'], required: true },
	{ Header: 'Campus/Work City', accessor: 'CITY', includedby: ['affil','non'], required: true },
	{ Header: 'Campus/Work State', accessor: 'STATE', includedby: ['affil','non'], required: true },
	{ Header: 'Campus/Work Zip', accessor: 'ZIP', includedby: ['affil','non'], required: true },
	{ Header: 'Accounts Payable/Admin First Name', accessor: 'ADMINFIRSTNAME', includedby: ['affil','non'], required: true },
	{ Header: 'Accounts Payable/Admin Last Name', accessor: 'ADMINLASTNAME', includedby: ['affil','non'], required: true },
	{ Header: 'Accounts Payable/Admin Phone', accessor: 'ADMINPHONE', includedby: ['affil','non'], required: true },
	{ Header: 'Accounts Payable/Admin Email', accessor: 'ADMINEMAIL', includedby: ['affil','non'], required: true },
	{ Header: 'Speedtype', accessor: 'SPEEDCODE', includedby: ['chem','affil'], required: true },
	{ Header: 'Grant/Funding Expiration Date', accessor: 'GRANTEXPDATE', includedby: ['chem','affil'], type: 'date', required: true },
	{ Header: 'Billing Account/Chart String', accessor: 'BILLINGCODE', includedby: ['chem','affil'], required: true },
	{ Header: 'Department', accessor: 'DEPARTMENT', includedby: ['chem','affil'], required: true },
	{ Header: 'Cal ID Front Card Number', accessor: 'FRONTIDENTITYCARD', includedby: ['chem','affil'], required: true },
	{ Header: 'PI', accessor: 'PIGRPNAME', type: 'select', includedby: 'chem', required: true, options: [] },
	{ Header: 'PI Name', accessor: 'PINAME', includedby: 'affil', required: true },
	{ Header: 'Organization', accessor: 'ORGANIZATION', includedby: 'non', required: true },
	{ Header: 'Organization Tax Id', accessor: 'TAXID', includedby: 'non', required: true },
	{ Header: 'Purchase Order', accessor: 'PURCHASEORDER', includedby: 'non', required: true }
];

export default withRouter(Home);