import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemTable from '../ChemTable';

class LDAP extends ChemComponent {
  	constructor(props) {
		super(props);
					
		this.state = {
			searchCount: null,
			ldapResults: [],
			renderKey: 1,
		};
	}
	
	onSubmitSearch(event) {
		var self = this;
		
		this.getActiveUIDList((uids, active) => {
			self.ajax({
				type: 'post',
				url: self.getConfig().host + '/Admin/UIDLookup',
				overlay: true,
				data: { 
					__RequestVerificationToken: self.props.user.antiForgeryToken,
					uid: uids
				}
			}).done(function (data) {
				if (data.Success) {
					// move backwards through active array so we can splice
					for (var a = active.length - 1; a >= 0; a--) {
						// add table id while we are here
						active[a].id = a;
						
						// if the user was confirmed active by the lookup
						for (var d = 0; d < data.Data.length; d++) {
							if (active[a].CAL_UID === data.Data[d].uid) {
								// remove user from active list
								active.splice(a, 1);
								
								// no need to search further
								break;
							}
						}
					}	
					
					// what is left now are "active" users that could not be confirmed by ldap
					self.mergeState({
						searchCount: active.length,
						ldapResults: active,
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	

			if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
		});
	}
	
	getActiveUIDList(callback) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			overlay: true,
			data: {
			  __RequestVerificationToken: this.props.user.antiForgeryToken,
			  columns: ['CAL_UID','LNAME','FNAME','MNAME','PREFERRED_NAME'],
			  entity: 'PEOPLE',
			  search: {
				Operator: 'and',
				Children: [
				  { Attribute: 'CAL_UID', Operator: 'is not null' },	
				  { Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }
				]
			  },
			  pageNumber: -1,
			  pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				// make a giant string with all the uids
				var uids = '';
				for (var i = 0; i < data.Data.length; i++) {
					if (i > 0) uids += ',';
					uids += data.Data[i].CAL_UID;
				}
				callback(uids, data.Data);
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	render() {
		
		var excelExportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Active Users No Longer At UCB',
			columns: exportColumns,
			data: this.state.ldapResults
		});	
		
		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>UID Lookup</span><br />
					{this.state.searchCount !== null && <span>
						{this.state.searchCount} records found
					</span>}
					<Button id="search" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
				  </Col>
				  <Col xs={1} style={{ marginTop: 6 }}>
					<form action={this.getConfig().host + '/Home/ExcelData'} method='POST'>
						{excelExportData.map((excelFormField, index) => {
							return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
						})}
						<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
						  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
						</OverlayTrigger>
					</form>
				  </Col>								  
				</Row>
				<Row>
				  <Col>
					<ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.ldapResults} columns={ldapColumns} name='WebAdmin_LDAP' />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const ldapColumns = [
	{ Header: 'UID', accessor: 'CAL_UID', width: 70 },
	{ Header: 'Last Name', accessor: 'LNAME', width: 125 },
	{ Header: 'First Name', accessor: 'FNAME', width: 125 },
	{ Header: 'Middle Name', accessor: 'MNAME', width: 125 },
	{ Header: 'Preferred Name', accessor: 'PREFERRED_NAME', width: 125 }
];

const exportColumns = [
	{ Header: 'UID', accessor: 'CAL_UID', width: 14 },
	{ Header: 'Last Name', accessor: 'LNAME', width: 25 },
	{ Header: 'First Name', accessor: 'FNAME', width: 25 },
	{ Header: 'Middle Name', accessor: 'MNAME', width: 25 },
	{ Header: 'Preferred Name', accessor: 'PREFERRED_NAME', width: 25 }
];

export default withRouter(LDAP);