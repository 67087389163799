import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Link } from 'react-router-dom';

class Application extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			application: {
				APPID: ''
			},
			appColumns: this.filterColumns(appColumns),
			piSelect: [],
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadPISelect(() => {
			this.loadApplication();
		});
	}

	loadPISelect(callback) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/GetFASPISelectView'
		}).done(function (data) {
			if (data.Success) {
				// create array for pi select options
				var piSelect = [
					{
						value: null,
						label: 'Select...'
					}
				];
				
				// for each result in the data
				for (var i = 0; i < data.Data.length; i++) {
					piSelect.push({
						value: data.Data[i].NAME,
						label: self.copyObject(data.Data[i].NAME)
					});
				}
				
				self.mergeState({
					piSelect: piSelect
				}, () => {
					if (callback) callback();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}

	loadApplication(event) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/FAS/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'RECH_APPLICATION_VW',
				search: { Attribute: 'APPID', Operator: '=', LongValue: this.props.params.appId },
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					var application = data.Data[0];
				
					// convert from mvc to js
					self.mvc2js(application, appColumns);
						
					self.mergeStateWithArrays({
						application: application,
						appColumns: self.filterColumns(appColumns, application.AFFILIATION),
						renderKey: self.state.renderKey + 1
					}, () => {
						if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
					});
				} else {
					self.showAlert('Application Error', 'Expected to find 1 application for ID ' + 
						this.props.params.appId + ' but found ' + data.Data.length);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
		
	onChange(accessor, value) {
		var application = this.copyObject(this.state.application);
		this.setByAccessor(application, accessor, value);
		
		this.setState({
			application: application,
			appColumns: this.filterColumns(appColumns, application.AFFILIATION),
			renderKey: this.state.renderKey + 1
		});
	}

	filterColumns(columns, filter) {
		var newColumns = [];
		
		for (var i = 0; i < columns.length; i++) {
			if (!columns[i].includedby ||
				(Array.isArray(columns[i].includedby) && columns[i].includedby.includes(filter)) ||
				(columns[i].includedby && columns[i].includedby === filter)) {
				// make a copy of the column
				var newColumn = this.copyObject(columns[i]);
				
				// if this is the PIGRPNAME column
				if (newColumn.accessor === 'PIGRPNAME') {
					// copy pi list to select options
					newColumn.options = this.copyObject(this.state.piSelect);
				}
				
				// add to new list of columns
				newColumns.push(newColumn);
			}
		}

		return newColumns;
	}
	
	validateApplication(event, columns, data) {
		return this.validate(columns, data);
	}

	onSubmit(event) {
		var self = this;
		var application = this.js2mvc(this.state.application, appColumns);

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/FAS/UpdateApplication',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				application: application
			}
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Application <Link to={'/FAS/Applications/' + self.props.params.appId}>{self.props.params.appId}</Link> updated.
					</>);
				});				
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
						  Application {this.state.application.APPID}
						</div>
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
						<ChemEdit parent={this} user={this.props.user} columns={this.state.appColumns} data={this.state.application}
							onChange={(accessor, value) => this.onChange(accessor, value)} renderKey={this.state.renderKey}
							validate={(event, columns, data) => this.validateApplication(event, columns, data)}
							onSubmit={(event) => this.onSubmit(event)}>
							<Form.Group as={Row}>
							  <Col style={{ borderTop: '1px solid #eee', paddingTop: '5px' }}>
								<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
							  </Col>
							</Form.Group>							
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const appColumns = [
	{ Header: 'Affiliation', accessor: 'AFFILIATION', type: 'select',
		options: [{ value: null, label: 'Select...' },{ value: 'chem', label: 'College of Chemistry Users' },
			{ value: 'affil', label: 'UCB or UC Affiliate Users' },{ value: 'non', label: 'Non-UC Users' }] },
	{ Header: 'Lab', accessor: 'FACILITYID', type: 'select',
		options: [{ value: null, label: 'Select...' },{ value: 34, label: 'Microanalytical Facility' },
			{ value: 33, label: 'X-Ray Crystallography' }]}, 
	{ Header: 'First Name', accessor: 'FIRSTNAME', maxlength: 45, required: true },
	{ Header: 'Middle Initial', accessor: 'MIDDLENAME', maxlength: 45 },
	{ Header: 'Last Name', accessor: 'LASTNAME', maxlength: 45, required: true },
	{ Header: 'Phone', accessor: 'PHONE', maxlength: 45, required: true },
	{ Header: 'Email', accessor: 'USERNAME', maxlength: 100, required: true },
	{ Header: 'Password', accessor: 'PASSWORD', maxlength: 20, required: true },
	{ Header: 'Office Room and Building', accessor: 'ROOMBUILD', maxlength: 45, includedby: 'chem', required: true },
	{ Header: 'Campus/Work Address', accessor: 'ADDRESS', includedby: ['affil','non'], maxlength: 45, required: true },
	{ Header: 'Campus/Work City', accessor: 'CITY', includedby: ['affil','non'], maxlength: 45, required: true },
	{ Header: 'Campus/Work State', accessor: 'STATE', includedby: ['affil','non'], maxlength: 45, required: true },
	{ Header: 'Campus/Work Zip', accessor: 'ZIP', includedby: ['affil','non'], maxlength: 45, required: true },
	{ Header: 'Accounts Payable/Admin First Name', accessor: 'ADMINFIRSTNAME', includedby: ['affil','non'], maxlength: 45, required: true },
	{ Header: 'Accounts Payable/Admin Last Name', accessor: 'ADMINLASTNAME', includedby: ['affil','non'], maxlength: 45, required: true },
	{ Header: 'Accounts Payable/Admin Phone', accessor: 'ADMINPHONE', includedby: ['affil','non'], maxlength: 20, required: true },
	{ Header: 'Accounts Payable/Admin Email', accessor: 'ADMINEMAIL', includedby: ['affil','non'], maxlength: 100, required: true },
	{ Header: 'Speedtype', accessor: 'SPEEDCODE', includedby: ['chem','affil'], maxlength: 45, required: true },
	{ Header: 'Grant/Funding Expiration Date', accessor: 'GRANTEXPDATE', includedby: ['chem','affil'], type: 'date', required: true },
	{ Header: 'Billing Account/Chart String', accessor: 'PURCHASEORDER', includedby: ['chem','affil'], maxlength: 100, required: true },
	{ Header: 'Department', accessor: 'DEPARTMENT', includedby: ['chem','affil'], maxlength: 100, required: true },
	{ Header: 'Cal ID Front Card Number', accessor: 'FRONTIDENTITYCARD', includedby: ['chem','affil'], maxlength: 45, required: true },
	{ Header: 'PI', accessor: 'PIGRPNAME', type: 'select', includedby: 'chem', required: true, options: [] },
	{ Header: 'PI Name', accessor: 'PINAME', includedby: 'affil', maxlength: 100, required: true },
	{ Header: 'Organization', accessor: 'ORGANIZATION', includedby: 'non', maxlength: 45, required: true },
	{ Header: 'Organization Tax Id', accessor: 'TAXID', includedby: 'non', maxlength: 45, required: true },
	{ Header: 'Purchase Order', accessor: 'PURCHASEORDER', includedby: 'non', maxlength: 100, required: true },
	{ Header: 'Sign Off Facility Administrator or FSA', accessor: 'SIGNOFFFACILITY', type: 'select',
		options: [{ value: null, label: 'Select...' },{ value: 0, label: 'Denied' },{ value: 1, label: 'Approved' }]},
	{ Header: 'Billing Code/Reference', accessor: 'BILLINGCODE', maxlength: 45 },
	{ Header: 'If denied, please explain reasons', accessor: 'FACILITYREASON', type: 'textarea' }
];

export default withRouter(Application);
