import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class DemTransaction extends ChemComponent {
	constructor(props) {
		super(props);
		
		var initTransColumns = this.copyColumns(transColumns);
		if (!props.params.transId) {
			var cylIdIdx = this.findColumnByAccessor(transColumns, 'CYL_ID');
			initTransColumns[cylIdIdx].editable = false;
		}
		
		this.state = {
			editable: props.editable,
			trans: {
				CYCLE_ID: ''
			},
			transColumns: this.copyColumns(transColumns),
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadDemTransaction();
	}

	loadDemTransaction(event) {
		var self = this;
		
		if (this.props.params.transId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					context: 'DEMURRAGE',
					entity: 'DEM_TRANS_DETAIL_VW',
					search: { Attribute: 'CYCLE_ID', Operator: '=', LongValue: this.props.params.transId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var trans = data.Data[0];
						self.mvc2js(trans, transColumns);
						
						trans.MODIFIED = self.composeChangeInfo(trans.MODIF_BY, trans.LAST_MODIF);
						
						// add default cost and out/return users to transColumns
						var newTransColumns = self.copyColumns(transColumns);
						var totalCostIdx = self.findColumnByAccessor(newTransColumns, 'LINE_TOTAL');
						newTransColumns[totalCostIdx].subscript = 'Default Price $' + trans.GAS_DEFAULT_PRICE;
						if (!self.isEmpty(trans.OUT_USER)) {
							var outUserIdx = self.findColumnByAccessor(newTransColumns, 'OUT_USER');
							newTransColumns[outUserIdx].options.search.Children.push({ Attribute: 'PERSON_ID', Operator: '=', LongValue: trans.OUT_USER });
						}
						if (!self.isEmpty(trans.RETURN_USER)) {
							var retUserIdx = self.findColumnByAccessor(newTransColumns, 'RETURN_USER');
							newTransColumns[retUserIdx].options.search.Children.push({ Attribute: 'PERSON_ID', Operator: '=', LongValue: trans.RETURN_USER });
						}
						if (!self.isEmpty(trans.RENTAL_RATE_ID)) {
							var rateIdx = self.findColumnByAccessor(newTransColumns, 'RENTAL_RATE_ID');
							newTransColumns[rateIdx].options.search.Children.push({ Attribute: 'RENTAL_RATE_ID', Operator: '=', LongValue: trans.RENTAL_RATE_ID });
						}
							
						self.mergeState({
							trans: trans,
							transColumns: newTransColumns,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Transaction Error', data.Data.length + ' Transactions found for ID ' + self.props.params.transId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				trans: {
					CYCLE_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var trans = this.copyObject(this.state.trans);
		this.setByAccessor(trans, accessor, value);
		if (accessor === 'CYL_ID') {
			this.getCylinderInfo(trans);
		} else {
			this.mergeState({ trans: trans, renderKey: this.state.renderKey + 1 });
		}
	}
	
	getCylinderInfo(trans) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/HeLAD/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				context: 'DEMURRAGE',
				entity: 'DEM_CYLS_VW',
				search: { Attribute: 'CYL_ID', Operator: '=', LongValue: trans.CYL_ID },
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					var cyl = data.Data[0];
					
					// set cylinder details
					trans.RENTAL_RATE_ID = cyl.RENTAL_RATE_ID;
					trans.GAS_NAME = cyl.GAS_NAME;
					trans.OWNER_NAME = cyl.OWNER_NAME;
					trans.LINE_TOTAL = cyl.GAS_COST;
					
					// set default price
					var newTransColumns = self.copyColumns(self.state.transColumns);
					var lineTotalIdx = self.findColumnByAccessor(newTransColumns, 'LINE_TOTAL');
					newTransColumns[lineTotalIdx].subscript = 'Default Price $' + cyl.GAS_DEFAULT_PRICE;
					
					self.mergeState({
						trans: trans,
						transColumns: transColumns,
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Cylinder Lookup Error', data.Data.length + ' cylinders found for ID ' + trans.CYL_ID);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
		
	deleteDemTransaction(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Transaction', 'Delete Transaction ' + this.props.params.transId + '?', (okClicked) => {
			if (okClicked) {
				// prepare trans for deletion		
				var trans = {
					CYCLE_ID: self.props.params.transId,
					DELETE_FLAG: 'Y',
					MODIF_BY: self.props.user.name,
					MODIF_DATE: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'DEMURRAGE.DEM_TRANS', data: trans }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Transaction {self.props.params.transId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}

	onSubmit(event) {
		var self = this;
		
		// make copy of data
		var trans = this.copyObject(this.state.trans);
		this.js2mvc(trans, transColumns);

		trans.MODIF_BY = this.props.user.name;
		trans.LAST_MODIF = this.dateTimeToMVC(new Date());
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/HeLAD/UpdateDemTransaction',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, transaction: trans }
		}).done(function (data) {
			if (data.Success) {
				var transId = self.props.params.transId || data.ID;
				var verb = self.props.params.transId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Transaction <Link to={'/HeLAD/DemTransactions/' + transId}>{transId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	renderBarcodeForm(cylinderId) {
		
		var barcodeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			cylinderid: cylinderId
		});
		
		return (
			<form action={this.getConfig().host + '/HeLAD/DemCylinderBarcode'} method='POST'>
				{barcodeData.map((pdfFormField, index) => {
					return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
				})}
				<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Barcode</Popover.Header></Popover>}>
					<input type='image' src={this.getConfig().host + '/Content/Icons/upc_lg.png'} alt="Print Barcode" />
				</OverlayTrigger>
			</form>
		);
	}

	render() {
		
		var printTransactionPDF = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			transid: this.props.params.transId
		});			
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Transaction {this.state.trans.CYCLE_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						{this.state.trans.CYL_ID && this.renderBarcodeForm(this.state.trans.CYL_ID)}
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/HeLAD/PrintDemTransaction'} method='POST'>
						  {printTransactionPDF.map((pdfFormField, index) => {
							return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
						  })}
						  <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							<input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
						  </OverlayTrigger>
						</form>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={transColumns} data={this.state.trans} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
						{this.state.editable && this.props.params.transId && <Button id="delete" variant="warning" type="button" className="float-end"
						  style={{ marginRight: '20px' }} onClick={(event) => this.deleteDemTransaction(event)}>Delete</Button>} 
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const transColumns = [
	{ Header: 'Vendor Name', accessor: 'OWNER_NAME', row: 0, col: 0, editable: false },
	{ Header: 'Gas Type', accessor: 'GAS_NAME', row: 0, col: 1, editable: false },
	{ Header: 'Cylinder Number', accessor: 'CYL_ID', type: 'select', row: 1, col: 0,
		options: { value: 'CYL_ID', label: 'MFR_CODE', entity: 'DEMURRAGE.DEM_CYLS', order: 'MFR_CODE',
			search: { Attribute: 'RETIRE_FLAG', Operator: 'is null' }}},
	{ Header: 'Total Cost $', accessor: 'LINE_TOTAL', row: 1, col: 1, subscript: 'Default Price $' },
	{ Header: 'Tracking Number', accessor: 'TRANS_ID', row: 2, col: 0, editable: false },
	{ Header: 'Rental Rate', accessor: 'RENTAL_RATE_ID', type: 'select', row: 2, col: 1,
		options: { value: 'RENTAL_RATE_ID', label: 'RENTAL_RATE_DESC', entity: 'DEMURRAGE.RENTAL_RATE_VW',
			search: { Operator: 'or', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' }
	]}}},
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', type: 'date', row: 3, col: 0, editable: false },
	{ Header: 'Surcharge', accessor: 'SURCHARGE', row: 3, col: 1 },
	{ Header: 'Customer Num In', accessor: 'REC_NUM_IN', row: 4, col: 0 },
	{ Header: 'Customer Num Out', accessor: 'REC_NUM_OUT', row: 4, col: 1 },
	{ Header: 'Tag Num In', accessor: 'TAG_NUM_IN', row: 5, col: 0 },
	{ Header: 'Tag Num Out', accessor: 'TAG_NUM_OUT', row: 5, col: 1 },
	{ Header: 'IOC Num In', accessor: 'IOC_NUM_IN', row: 6, col: 0 },
	{ Header: 'IOC Num Out', accessor: 'IOC_NUM_OUT', row: 6, col: 1 },
	{ Header: 'Date In College', accessor: 'DATE_IN_COLLEGE', type: 'date', row: 7, col: 0 },
	{ Header: 'Date Out College', accessor: 'DATE_OUT_COLLEGE', type: 'date', row: 7, col: 1 },
	{ Header: 'Out User', accessor: 'OUT_USER', type: 'select', row: 8, col: 0,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'DEMURRAGE.DEM_USER_SELECT_VW', order: 'USER_NAME',
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }
	]}}},
	{ Header: 'Return User', accessor: 'RETURN_USER', type: 'select', row: 8, col: 1,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'DEMURRAGE.DEM_USER_SELECT_VW', order: 'USER_NAME',
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }
	]}}},
	{ Header: 'Out Date', accessor: 'OUT_DATE', row: 9, col: 0 },
	{ Header: 'Return Date', accessor: 'RETURN_DATE', row: 9, col: 1 },
	{ Header: 'PI', accessor: 'PI_NAME', row: 10, col: 0, editable: false },
	{ Header: 'Last Modified', accessor: 'MODIFIED', row: 10, col: 1 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 11, col: 0 },
	{ Header: 'Remark', accessor: 'REMARK', type: 'textarea', row: 11, col: 1 }
];

export default withRouter(DemTransaction);
