import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Groups extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			searchCount: null,
			renderKey: 0
		};
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	addGroup() {
		this.props.navigate('/FAS/Groups/new');
	}
	
	editGroup(groupId) {
		this.props.navigate('/FAS/Groups/' + groupId);
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Groups',
			columns: exportColumns,
			entity: 'FAS.PIGROUP_VW',
			order: 'PI'
		});			
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Groups</div>
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='FAS_Groups' table={groupList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='150px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const groupList = {
	query: { entity: 'FAS.PIGROUP_VW', order: 'PI' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addGroup()} />, 
			accessor: 'ID', width: 25,
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editGroup(props.value)} /> },
		{ Header: 'PI', accessor: 'PI', width: 250 },
		{ Header: 'Name', accessor: 'NAME', width: 250 }
	]
};

const exportColumns = [
	{ Header: 'PI', accessor: 'PI', width: 50 },
	{ Header: 'Name', accessor: 'NAME', width: 50 }
];

export default withRouter(Groups);
