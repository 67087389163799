import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import HashLink from '../HashLink';
import EditTable from '../EditTable';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill, Bag, BagX } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Sale extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			sale: {
				store_sale: {
					SALE_ID: '',
				},
				sale_detail: [],
				new_detail: [],
				returns: {},
				return_detail: []
			},
			prices: {},
			saleColumns: this.copySaleColumns(saleColumns),
			editorsOpen: [],
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadSale();
	}
	
	copySaleColumns(oldSaleColumns) {
		var newSaleColumns = this.copyColumns(oldSaleColumns);
		if (this.props.params.saleId) {
			// existing transactions can't be edited
			for (var i = 0; i < newSaleColumns.length; i++) {
				newSaleColumns[i].editable = false;
			}
		}
		
		return newSaleColumns;
	}

	loadSale(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/GetSale',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				saleid: this.props.params.saleId
			}
		}).done(function (data) {
			if (data.Success) {
				var sale = data.Data;
				var store_sale = sale.store_sale;
				var prices = sale.prices;
				delete sale.prices;
				self.mvc2js(store_sale, saleColumns);
				store_sale.MODIFIED = self.composeChangeInfo(store_sale.MODIF_BY, store_sale.MODIF_DATE);
				sale.new_detail = [];
				
				// if this is a manual sale
				if (!self.props.params.saleId) {
					// set type to 'book' and check correction checkbox
					store_sale.SALE_ID = '(new)';
					store_sale.MANUAL_FLAG = 'Y';
					store_sale.CORRECTION_FLAG = true;
				}
				
				var newSaleColumns = self.copySaleColumns(saleColumns);
				var custIdx = self.findColumnByAccessor(newSaleColumns, 'store_sale.USER_NAME');
				if (!self.isEmpty(store_sale.PERSON_ID)) {
					newSaleColumns[custIdx].options.search.Children.push({ Attribute: 'PERSON_ID', Operator: '=', LongValue: store_sale.PERSON_ID });
				}
				var piIdx = self.findColumnByAccessor(newSaleColumns, 'store_sale.PI_ROLE_ID');
				if (!self.isEmpty(store_sale.PI_ROLE_ID)) {
					newSaleColumns[piIdx].options.search.Children.push({ Attribute: 'ROLE_ID', Operator: '=', LongValue: store_sale.PI_ROLE_ID });
					self.restrictCCtoPI(newSaleColumns, store_sale.PI_ROLE_ID);
				}
				var ccIdx = self.findColumnByAccessor(newSaleColumns, 'store_sale.COST_CENTER');
				if (!self.isEmpty(store_sale.COST_CENTER)) {
					newSaleColumns[ccIdx].options.search.Children.push({ Attribute: 'SPEEDTYPE', Operator: '=', StringValue: store_sale.COST_CENTER });
				}
				sale.returns = {
					COMMENTS: ''
				};
				
				self.mergeState({
					sale: sale,
					prices: prices,
					saleColumns: newSaleColumns,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
		
	onEdit(accessor, editorIsOpen) {
		this.mergeState({
			editorsOpen: this.setEditorStatus(accessor, editorIsOpen),
			renderKey: this.state.renderKey + 1
		});
	}
	
	setEditorStatus(accessor, editorIsOpen) {
		var editorsOpen = this.copyObject(this.state.editorsOpen);
		if (editorIsOpen) {
			// add to list if it isn't already present
			if (!editorsOpen.includes(accessor)) editorsOpen.push(accessor);
		} else {			
			// remove from list if it is present
			if (editorsOpen.includes(accessor)) editorsOpen.splice(editorsOpen.indexOf(accessor), 1);
		}
		return editorsOpen;
	}	

	viewItem(itemId) {
		this.props.navigate('/Stores/Catalog/' + itemId);
	}
	
	onChange(accessor, value) {
		var sale = this.copyObject(this.state.sale);
		var newSaleColumns = null;
		var newEditorsOpen;
		if (Array.isArray(accessor)) {
			for (var i = 0; i < accessor.length; i++) {
				this.setByAccessor(sale, accessor[i], value[i]);
			}
		} else {
			this.setByAccessor(sale, accessor, value);
			if (accessor === 'store_sale.PI_ROLE_ID') {
				// restrict cost center to those owned by the newly chosen PI
				newSaleColumns = this.copySaleColumns(this.state.saleColumns);
				this.restrictCCtoPI(newSaleColumns, value);
			} else if (accessor === 'new_detail') {
				this.splitStockNumDescription(value);
				newEditorsOpen = this.setEditorStatus(accessor, false);
			}
		}
		if (!newEditorsOpen) newEditorsOpen = this.copyObject(this.state.editorsOpen);
		if (newSaleColumns) {
			this.mergeState({ sale: sale, editorsOpen: newEditorsOpen, saleColumns: newSaleColumns, renderKey: this.state.renderKey + 1 });
		} else {
			this.mergeState({ sale: sale, editorsOpen: newEditorsOpen, renderKey: this.state.renderKey + 1 });
		}
	}
	
	splitStockNumDescription(sale_detail) {
		for (var i = 0; i < sale_detail.length; i++) {
			if (sale_detail[i].STOCK_NO_DESCRIPTION) {
				var spaceIdx = sale_detail[i].STOCK_NO_DESCRIPTION.indexOf(' ');
				if (spaceIdx >= 0) {
					sale_detail[i].STOCK_NO = sale_detail[i].STOCK_NO_DESCRIPTION.substring(0, spaceIdx);
					sale_detail[i].DESCRIPTION = sale_detail[i].STOCK_NO_DESCRIPTION.substring(spaceIdx + 1);
				} else {
					sale_detail[i].STOCK_NO = sale_detail[i].STOCK_NO_DESCRIPTION;
					sale_detail[i].DESCRIPTION = '';
				}
			}
		}
	}
	
	onChangeSaleDetail(detail, accessor, value) {
		if (accessor === 'STOCK_NO_DESCRIPTION') {
			this.setByAccessor(detail, 'STOCK_NO_DESCRIPTION_LIKE', '%' + value.toUpperCase() + '%');
			var costinfo = this.state.prices[value.toUpperCase()];
			if (costinfo !== undefined && costinfo !== null) {
				this.setByAccessor(detail, 'UNIT', costinfo.UNIT);
				this.setByAccessor(detail, 'UNIT_COST', costinfo.UNIT_COST);
			}
		} else if (accessor === 'QUANTITY' || accessor === 'UNIT_COST') {
			var qty;
			var cost;
			if (accessor === 'QUANTITY') {
				qty = value;
				cost = this.getByAccessor(detail, 'UNIT_COST');
			} else {
				qty = this.getByAccessor(detail, 'QUANTITY');
				cost = value;
			}
			if (this.isNumeric(qty) && this.isNumeric(cost)) {
				this.setByAccessor(detail, 'LINE_TOTAL', (+qty * +cost).toFixed(2));
			} else {
				this.setByAccessor(detail, 'LINE_TOTAL', '');
			}
		}
	}
	
	restrictCCtoPI(newSaleColumns, roleId) {
		var ccIdx = this.findColumnByAccessor(newSaleColumns, 'store_sale.COST_CENTER');
		for (var i = 0; i < newSaleColumns[ccIdx].options.search.Children.length; i++) {
			var child = newSaleColumns[ccIdx].options.search.Children[i];
			if (child.Attribute === 'ROLE_ID') {
				if (this.isEmpty(roleId)) {
					child.Operator = 'is not null';
					if (child.LongValue !== undefined) delete child.LongValue;
				} else {
					child.Operator = '=';
					child.LongValue = roleId;
				}
			}
		}
	}
	
	findRowByLineID(rows, line_id) {
		for (var i = 0; i < rows.length; i++) {
			if (rows[i].LINE_ID === line_id) return i;
		}
		return -1;
	}
	
	returnLineItem(lineId) {
		// make copy of sale
		var sale = this.copyObject(this.state.sale);
		
		// locate sale detail line
		var lineIdx = this.findRowByLineID(sale.sale_detail, lineId);
		
		// if line was located
		if (lineIdx >= 0) {
			// make extra copy of line
			var return_line = this.copyObject(sale.sale_detail[lineIdx]);
			
			// negate quantity and line total
			return_line.QUANTITY = -return_line.QUANTITY;
			return_line.LINE_TOTAL = -return_line.LINE_TOTAL;
			
			// append to return detail
			sale.return_detail.push(return_line);
		}
		
		this.mergeState({ sale: sale });
	}
	
	undoReturnLineItem(lineId) {
		// make copy of sale
		var sale = this.copyObject(this.state.sale);
		
		// locate return detail
		var lineIdx = this.findRowByLineID(sale.return_detail, lineId);
		
		// if line was located
		if (lineIdx >= 0) {
			// remove line from return detail
			sale.return_detail.splice(lineIdx, 1);
		}
		
		this.mergeState({ sale: sale });
	}		
		
	onSubmit(event) {
		var self = this;
			
		if (this.state.editorsOpen.includes('sale_detail') ||
			this.state.editorsOpen.includes('new_detail') ||
			this.state.editorsOpen.includes('return_detail')) {
			this.showAlert('Editor Open', 'Please close the line item editor before saving.');
		} else {
			// make copy of data
			var sale = this.copyObject(this.state.sale);
			var store_sale = sale.store_sale;

			this.js2mvc(sale, saleColumns);
			store_sale.MODIF_BY = this.props.user.name;
			store_sale.MODIF_DATE = this.dateTimeToMVC(new Date());

			// if this is a new sale
			if (!this.props.params.saleId) {
				// set ID to null
				store_sale.SALE_ID = 'store_sale_seq.nextval';
			}

			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Stores/UpdateSale',
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, sale: sale }
			}).done(function (data) {
				if (data.Success) {
					var saleId = self.props.params.saleId || data.ID;
					var returnId = self.props.params.saleId ? data.ID : 0;
					var verb = self.props.params.saleId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							Sale <Link to={'/Stores/Sales/' + saleId}>{saleId}</Link> {verb}. 
							{returnId > 0 && <>&nbsp;Return <Link to={'/Stores/Sales/' + returnId}>{returnId}</Link> created.</>}
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Sale {this.state.sale.store_sale.SALE_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={this.state.saleColumns} data={this.state.sale} user={this.props.user} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					{this.props.params.saleId &&
						<Row>
						  <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
							<h5>Line Items</h5>
							<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyLineItem} data={this.state.sale.sale_detail}
							  tableColumns={detailTableColumns} editColumns={detailEditColumns} onEdit={(editorIsOpen) => this.onEdit('sale_detail', editorIsOpen)}
							  onChange={(data) => this.onChange('sale_detail', data)} tableName='Stores_Sale_Detail' order='LINE_ID' editable={false}
							  onChangeInternal={(data, accessor, value) => this.onChangeSaleDetail(data, accessor, value)} />
						  </Col>
						</Row>
					}
					<Row>
					  <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
						<h5>New Line Items</h5>
						<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyLineItem} data={this.state.sale.new_detail}
						  tableColumns={newDetailTableColumns} editColumns={newDetailEditColumns} onEdit={(editorIsOpen) => this.onEdit('new_detail', editorIsOpen)}
						  onChange={(data) => this.onChange('new_detail', data)} tableName='Stores_Sale_New_Detail' order='LINE_ID' orderOffset={this.state.sale.sale_detail.length}
						  editable={this.state.editable} onChangeInternal={(data, accessor, value) => this.onChangeSaleDetail(data, accessor, value)} />
					  </Col>
					</Row>
					{this.props.params.saleId && !this.state.sale.store_sale.PARENT_ID && <>
					<Row>
					  <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
						<h5>Returns</h5>
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
					    <ChemEdit parent={this} columns={returnReasonColumns} data={this.state.sale} user={this.props.user} renderKey={this.state.renderKey}
						  onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} labelColWidth={3} valueColWidth={9} />
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
						<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyReturn} data={this.state.sale.return_detail}
						  tableColumns={returnTableColumns} editColumns={returnEditColumns} tableName='Stores_Sale_Returns' order='LINE_ID' editable={false} />
					  </Col>
					</Row>
					</>}
					<Row>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const saleColumns = [
	{ Header: 'Customer', valueAccessor: 'store_sale.PERSON_ID', accessor: 'store_sale.USER_NAME', type: 'dropdown', row: 0, col: 0,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'STORES.SALE_CUSTOMER_SELECT_VW', order: 'USER_NAME_UCASE',
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }
				// PERSON_ID = sale.PERSON_ID will be added when sale is loaded so that inactive customers will still appear
	]}}},
	{ Header: 'Sale Date', accessor: 'store_sale.TRANS_DATE', type: 'datetime', row: 0, col: 1 },
	{ Header: 'PI', accessor: 'store_sale.PI_ROLE_ID', type: 'select', row: 1, col: 0,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'STORES.SALE_PI_SELECT_VW', order: 'PI_NAME_UCASE',
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_ROLE_CC_FLAG', Operator: '=', StringValue: 'Y' }
				// ROLE_ID = sale.PI_ROLE_ID will be added when sale is loaded so that inactive PIs will still appear
	]}}},
	{ Header: 'Speedtype', accessor: 'store_sale.COST_CENTER', type: 'select', row: 1, col: 1,
		options: { value: 'SPEEDTYPE', label: 'CC_PI', entity: 'STORES.SALE_SPEEDTYPE_SELECT_VW', order: 'PI_NAME',
			search: { Operator: 'or', Children: [
				{ Attribute: 'ROLE_ID', Operator: 'is not null' }
				// COST_CENTER = sale.COST_CENTER will be added when sale is loaded so that inactive CCs will still appear
	]}}},
	{ Header: 'Sale Type', accessor: 'store_sale.MANUAL_FLAG', type: 'select', row: 2, col: 0,
		options: [{ value: '', label: 'Chk out' },{ value: 'Y', label: 'Book' }]},
	{ Header: 'Correction', accessor: 'store_sale.CORRECTION_FLAG', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Comments', accessor: 'store_sale.COMMENTS', type: 'textarea', row: 3, col: 0 },
	{ Header: 'Date Billed', accessor: 'store_sale.DATE_BILLED', row: 3, col: 1, editable: false },
	{ Header: 'Modified', accessor: 'store_sale.MODIFIED', row: 4, col: 0, editable: false },
	{ Header: 'Last Action', accessor: 'store_sale.MODIF_ACTION', row: 4, col: 1, editable: false }
];

const emptyLineItem = {
	LINE_ID: null,
	STOCK_NO: '',
	DESCRIPTION: '',
	STOCK_NO_DESCRIPTION: '',
	STOCK_NO_DESCRIPTION_LIKE: '',
	QUANTITY: '',
	UNIT: '',
	UNIT_COST: '',
	LINE_TOTAL: ''
};

const detailTableColumns = [
	{ Header: 'Item', accessor: 'LINE_ID', width: 50 },
	{ Header: '', accessor: 'ITEM_ID', show: false },
	{ Header: 'Stock No.', accessor: 'STOCK_NO', width: 100,
		Cell: props => props.row.values.ITEM_ID ? <HashLink onClick={() => 
			props.parent.props.parent.viewItem(props.row.values.ITEM_ID)}>{props.value}</HashLink> : props.value },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 350 },
	{ Header: 'Quantity', accessor: 'QUANTITY', width: 80 },
	{ Header: 'Unit', accessor: 'UNIT', width: 50 },
	{ Header: 'Price', accessor: 'UNIT_COST', width: 60 },
	{ Header: 'Total', accessor: 'LINE_TOTAL', width: 80 },
	{ Header: 'Return', accessor: 'RETURN', width: 70, Cell: props => {
		var saleEditor = props.parent.props.parent;
		return saleEditor.state.editable && saleEditor.props.params.saleId && !saleEditor.state.sale.store_sale.PARENT_ID &&
			saleEditor.findRowByLineID(saleEditor.state.sale.return_detail, props.row.values.LINE_ID) < 0 &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<BagX style={{ cursor: 'pointer' }} onClick={() => saleEditor.returnLineItem(props.row.values.LINE_ID)} />
		</div>
	}}
];

const detailEditColumns = [
	{ Header: 'Item', accessor: 'LINE_ID', row: 0, col: 0, editable: false },
	{ Header: 'Stock Number', accessor: 'STOCK_NO_DESCRIPTION', type: 'dropdown', row: 0, col: 1,
		options: { value: 'STOCK_NO', label: 'STOCK_NO', entity: 'STORES.STOCK_DESC_AUTOSUGGEST_VW', order: 'STOCK_NO', distinct: true,
			search: { Attribute: 'STOCK_NO', Operator: 'like', StringAccessor: 'STOCK_NO_DESCRIPTION_LIKE' }	
	}},
	{ Header: 'Quantity', accessor: 'QUANTITY', row: 1, col: 0 },
	{ Header: 'Unit', accessor: 'UNIT', row: 1, col: 1 },
	{ Header: 'Price', accessor: 'UNIT_COST', row: 2, col: 0 },
	{ Header: 'Total', accessor: 'LINE_TOTAL', row: 2, col: 1, editable: false }
];

const newDetailTableColumns = [
	{ Header: 'Item', accessor: 'LINE_ID', width: 50 },
	{ Header: '', accessor: 'ITEM_ID', show: false },
	{ Header: 'Stock No.', accessor: 'STOCK_NO', width: 100,
		Cell: props => props.row.values.ITEM_ID ? <HashLink onClick={() => 
			props.parent.props.parent.viewItem(props.row.values.ITEM_ID)}>{props.value}</HashLink> : props.value },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 350 },
	{ Header: 'Quantity', accessor: 'QUANTITY', width: 80 },
	{ Header: 'Unit', accessor: 'UNIT', width: 50 },
	{ Header: 'Price', accessor: 'UNIT_COST', width: 60 },
	{ Header: 'Total', accessor: 'LINE_TOTAL', width: 80 }
];

const newDetailEditColumns = [
	{ Header: 'Item', accessor: 'LINE_ID', row: 0, col: 0, editable: false },
	{ Header: 'Stock Number', accessor: 'STOCK_NO_DESCRIPTION', type: 'dropdown', row: 0, col: 1,
		options: { value: 'STOCK_NO', label: 'STOCK_NO', entity: 'STORES.STOCK_DESC_AUTOSUGGEST_VW', order: 'STOCK_NO', distinct: true,
			search: { Attribute: 'STOCK_NO', Operator: 'like', StringAccessor: 'STOCK_NO_DESCRIPTION_LIKE' }	
	}},
	{ Header: 'Quantity', accessor: 'QUANTITY', row: 1, col: 0 },
	{ Header: 'Unit', accessor: 'UNIT', row: 1, col: 1 },
	{ Header: 'Price', accessor: 'UNIT_COST', row: 2, col: 0 },
	{ Header: 'Total', accessor: 'LINE_TOTAL', row: 2, col: 1, editable: false }
];

const emptyReturn = {
	LINE_ID: null,
	STOCK_NO: '',
	DESCRIPTION: '',
	STOCK_NO_DESCRIPTION: '',
	STOCK_NO_DESCRIPTION_LIKE: '',
	QUANTITY: '',
	UNIT: '',
	UNIT_COST: '',
	LINE_TOTAL: ''
};

const returnReasonColumns = [
	{ Header: 'Note/Explanation', accessor: 'returns.COMMENTS', type: 'textarea' }
];

const returnTableColumns = [
	{ Header: 'Item', accessor: 'LINE_ID', width: 50 },
	{ Header: '', accessor: 'ITEM_ID', show: false },
	{ Header: 'Stock No.', accessor: 'STOCK_NO', width: 100,
		Cell: props => props.row.values.ITEM_ID ? <HashLink onClick={() => 
			props.parent.props.parent.viewItem(props.row.values.ITEM_ID)}>{props.value}</HashLink> : props.value },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 350 },
	{ Header: 'Quantity', accessor: 'QUANTITY', width: 80 },
	{ Header: 'Unit', accessor: 'UNIT', width: 50 },
	{ Header: 'Price', accessor: 'UNIT_COST', width: 60 },
	{ Header: 'Total', accessor: 'LINE_TOTAL', width: 80 },
	{ Header: 'Return', accessor: 'SALE_ID', width: 70, Cell: props => {
		var saleEditor = props.parent.props.parent;

		if (saleEditor.state.editable && saleEditor.props.params.saleId && +saleEditor.props.params.saleId === +props.row.values.SALE_ID) {
			// returning an item from this sale; show 'undo' button
			return (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Bag style={{ cursor: 'pointer' }} onClick={() => saleEditor.undoReturnLineItem(props.row.values.LINE_ID)} />
				</div>
			);
		} else if (saleEditor.props.params.saleId && +saleEditor.props.params.saleId !== +props.row.values.SALE_ID) {
			// previously returned item, so link to its return record
			return (
				<a href={'/Stores/Sales/' + props.row.values.SALE_ID}>{props.row.values.SALE_ID}</a>
			);
		}
	}}
];

const returnEditColumns = [
	{ Header: 'Item', accessor: 'LINE_ID', row: 0, col: 0, editable: false },
	{ Header: 'Stock Number', accessor: 'STOCK_NO_DESCRIPTION', type: 'dropdown', row: 0, col: 1,
		options: { value: 'STOCK_NO', label: 'STOCK_NO', entity: 'STORES.STOCK_DESC_AUTOSUGGEST_VW', order: 'STOCK_NO', distinct: true,
			search: { Attribute: 'DESCRIPTION', Operator: 'like', StringAccessor: 'STOCK_NO_DESCRIPTION_LIKE' }	
	}},
	{ Header: 'Quantity', accessor: 'QUANTITY', row: 1, col: 0 },
	{ Header: 'Unit', accessor: 'UNIT', row: 1, col: 1 },
	{ Header: 'Price', accessor: 'UNIT_COST', row: 2, col: 0 },
	{ Header: 'Total', accessor: 'LINE_TOTAL', row: 2, col: 1, editable: false }
];

export default withRouter(Sale);
