import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Link } from 'react-router-dom';

class Microrequest extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			request: {
				microrequest: {
					IDDISPLAY: ''
				},
				users: {
					AFFILIATIONDISPLAY: ''
				}
			},
			requestColumns: this.filterColumns(requestColumns),
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadRequest();
	}

	loadRequest(event) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/FAS/GetMicrorequest',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				request: {
					id: this.props.params.requestId
				}
			}
		}).done(function (data) {
			if (data.Success) {
				var request = data.Data;
			
				// convert from mvc to js
				self.mvc2js(request, requestColumns);
				
				// add prefix to ID for display
				request.microrequest.IDDISPLAY = self.props.params.requestId ?
					('M-' + request.microrequest.ID) : '(new)';
					
				// interpret affiliation for display
				if (request.users.AFFILIATION === 'chem') {
					request.users.AFFILIATIONDISPLAY = 'Chemistry';
				} else if (request.users.AFFILIATION === 'affil') {
					request.users.AFFILIATIONDISPLAY = 'Affiliate';
				} else if (request.users.AFFILIATION === 'non') {
					request.users.AFFILIATIONDISPLAY = 'Non UC users';
				} else {
					request.users.AFFILIATIONDISPLAY = '(unknown)';
				}
				
				// if there is no PO number in the request
				if (self.isEmpty(request.microrequest.PONUMBER)) {
					// get the user's default purchase order
					request.microrequest.PONUMBER = request.users.PURCHASEORDER;
				}
				
				if (request.users.AFFILIATION !== 'non') {
					// if there is no speedtype in the request
					if (self.isEmpty(request.microrequest.SPEEDCODE)) {
						// get the user's default speedcode
						request.microrequest.SPEEDCODE = request.users.SPEEDCODE;
					}
				}
					
				self.mergeStateWithArrays({
					request: request,
					requestColumns: self.filterColumns(requestColumns, request.users.AFFILIATION),
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
		
	onChange(accessor, value) {
		var request = this.copyObject(this.state.request);
		this.setByAccessor(request, accessor, value);
		
		this.setState({
			request: request,
			requestColumns: this.filterColumns(requestColumns, request.users.AFFILIATION),
			renderKey: this.state.renderKey + 1
		});
	}

	filterColumns(columns, filter) {
		var newColumns = [];
		
		for (var i = 0; i < columns.length; i++) {
			if (!columns[i].includedby ||
				(Array.isArray(columns[i].includedby) && columns[i].includedby.includes(filter)) ||
				(columns[i].includedby && columns[i].includedby === filter)) {
				// make a copy of the column
				var newColumn = this.copyObject(columns[i]);
				
				// add to new list of columns
				newColumns.push(newColumn);
			}
		}

		return newColumns;
	}
	
	validateRequest(event, columns, data) {
		return this.validate(columns, data);
	}

	onSubmit(event) {
		var self = this;
		var request = this.js2mvc(this.state.request, requestColumns);

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/FAS/UpdateRequest',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				request: request
			}
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					var requestId = self.props.params.requestId || data.ID;
					var verb = self.props.params.requestId ? 'updated' : 'added';
					return (<>
						Request <Link to={'/FAS/Microrequests/' + requestId}>{requestId}</Link> {verb}.
					</>);
				});				
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
						  MICROANALYTICAL Request {this.state.request.microrequest.IDDISPLAY} - {this.state.request.users.AFFILIATIONDISPLAY}
						</div>
						Fields with <font color="red">*</font> are required
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
						<ChemEdit parent={this} user={this.props.user} columns={this.state.requestColumns} data={this.state.request}
							onChange={(accessor, value) => this.onChange(accessor, value)} renderKey={this.state.renderKey}
							validate={(event, columns, data) => this.validateRequest(event, columns, data)}
							onSubmit={(event) => this.onSubmit(event)}>
							<Form.Group as={Row}>
							  <Col style={{ borderTop: '1px solid #eee', paddingTop: '5px' }}>
								<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
							  </Col>
							</Form.Group>							
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const requestColumns = [
	{ Header: 'Request Date', accessor: 'microrequest.REQUESTDATE', type: 'date', row: 0, col: 0, editable: false },
	{ Header: 'Request No.', accessor: 'microrequest.IDDISPLAY', row: 0, col: 1, editable: false },
	{ Header: 'Complete Date', accessor: 'microrequest.DATECOMP', type: 'date', row: 1, col: 0, editable: false },
	{ Header: 'Billing Date', accessor: 'microrequest.BILLINGDATE', type: 'date', row: 1, col: 1, editable: false },
	{ Header: 'First Name', accessor: 'users.FIRSTNAME', row: 2, col: 0, editable: false },
	{ Header: 'Last Name', accessor: 'users.LASTNAME', row: 2, col: 1, editable: false },
	{ Header: 'PI Group', accessor: 'users.PIGRPNAME', row: 3, col: 0, includedby: 'chem', editable: false },
	{ Header: 'Faculty Advisor', accessor: 'users.PINAME', row: 3, col: 0, includedby: 'affil', editable: false },
	{ Header: 'Organization', accessor: 'users.ORGANIZATION', row: 3, col: 0, includedby: 'non', editable: false },
	{ Header: 'Phone Number', accessor: 'users.PHONE', row: 3, col: 1, editable: false },
	{ Header: 'Sample Code', accessor: 'microrequest.SAMPLECODE', row: 4, col: 0, required: true },
	{ Header: 'Campus Address', accessor: 'users.ROOMBUILD', row: 4, col: 1, editable: false },
	{ Header: 'Purchase Order', accessor: 'microrequest.PONUMBER', row: 5, col: 0, includedby: 'non', required: true },
	{ Header: 'Speedtype', accessor: 'microrequest.SPEEDTYPE', row: 5, col: 0, includedby: ['chem','affil'], required: true },
	{ Header: 'Email Address', accessor: 'users.USERNAME', row: 5, col: 1, editable: false },
	{ Header: 'Chartstring', accessor: 'microrequest.PONUMBER', row: 6, col: 0, includedby: ['chem','affil'], required: true },
	
	
];

export default withRouter(Microrequest);
