import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import Tabs from '../Tabs';
import { PencilSquare } from 'react-bootstrap-icons';

class ReturnManifest extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: null,
			returnManifestLists: [],
			search: {
				TAG_NUM_OUT: null
			},
			searchCount: 0,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/HeLAD/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				context: 'DEMURRAGE',
				entity: 'TAG_NUM_OUT_OWNERS_VW',
				search: { Attribute: 'TAG_NUM_OUT', Operator: '=', StringValue: this.state.search.TAG_NUM_OUT },
				order: 'OWNER_NAME',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {	
				var returnManifestLists = [];
				var activeTab = null;
				for (var i = 0; i < data.Data.length; i++) {
					if (i === 0) activeTab = data.Data[i].OWNER_NAME;
					var newReturnManifestList = self.copyTable(returnManifestList);
					newReturnManifestList.query.search = self.constructSearch({ 
						TAG_NUM_OUT: self.state.search.TAG_NUM_OUT,
						OWNER_NAME: data.Data[i].OWNER_NAME
					});
					newReturnManifestList.owner_id = data.Data[i].OWNER_ID;
					newReturnManifestList.tab = data.Data[i].OWNER_NAME;
					returnManifestLists.push(newReturnManifestList);
				}
						
				self.mergeState({
					activeTab,
					searchCount: returnManifestLists.length > 0 ? null : 0,
					returnManifestLists: returnManifestLists,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.TAG_NUM_OUT)) search.Children.push({ Attribute: 'TAG_NUM_OUT', Operator: '=', StringValue: data.TAG_NUM_OUT });
		if (!this.isEmpty(data.OWNER_NAME)) search.Children.push({ Attribute: 'OWNER_NAME', Operator: '=', StringValue: data.OWNER_NAME });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	editDemTransaction(demTransactionId) {
		this.props.setDemTransactionEditable(true);
		this.props.navigate('/HeLAD/DemTransactions/' + demTransactionId);		
	}

	viewDemTransaction(demTransactionId) {
		this.props.setDemTransactionEditable(false);
		this.props.navigate('/HeLAD/DemTransactions/' + demTransactionId);		
	}
	
	onSearchResult(data, index) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var activeManifestList = null;
		var exportData = null;
		if (this.state.returnManifestLists.length === 1) {
			activeManifestList = this.state.returnManifestLists[0];
			exportData = this.createHiddenFormData({
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				title: 'Return Manifest ' + this.state.search.TAG_NUM_OUT + ' - ' + this.state.returnManifestLists[0].tab,
				columns: exportColumns,
				entity: 'DEMURRAGE.RETURN_MANIFEST_VW',
				search: this.constructSearch({
					TAG_NUM_OUT: this.state.search.TAG_NUM_OUT,
					OWNER_NAME: this.state.returnManifestLists[0].tab
				}),
				order: 'MFR_CODE'
			});
			var manifestData = this.createHiddenFormData({
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				tagnumout: this.state.search.TAG_NUM_OUT,
				ownerid: this.state.returnManifestLists[0].owner_id
			});
		} else if (this.state.returnManifestLists.length > 1) {
			for (var i = 0; i < this.state.returnManifestLists.length; i++) {
				if (this.state.returnManifestLists[i].tab === this.state.activeTab) {
					activeManifestList = this.state.returnManifestLists[i];
					break;
				}
			}
		}
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Return Manifest</span>
						<ChemEdit parent={this} user={this.props.user} columns={returnManifestSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={9}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
					{this.state.returnManifestLists.length > 1 &&
						<Row>
							<Col>
								<Tabs activeTab={this.state.activeTab} onClickTabItem={(tab) => this.mergeState({ activeTab: tab, searchCount: null, renderKey: this.state.renderKey + 1 })}>
									{this.state.returnManifestLists.map((list, index) => {
										
										var tabExportData = this.createHiddenFormData({
											__RequestVerificationToken: this.props.user.antiForgeryToken,
											title: 'Return Manifest ' + this.state.search.TAG_NUM_OUT + ' - ' + list.tab,
											columns: exportColumns,
											entity: 'DEMURRAGE.RETURN_MANIFEST_VW',
											search: this.constructSearch({
												TAG_NUM_OUT: this.state.search.TAG_NUM_OUT,
												OWNER_NAME: list.tab
											}),
											order: 'MFR_CODE'
										});
										
										var tabManifestData = this.createHiddenFormData({
											__RequestVerificationToken: this.props.user.antiForgeryToken,
											tagnumout: this.state.search.TAG_NUM_OUT,
											ownerid: list.owner_id
										});

										return (
											<div label={list.tab}>
												<Container fluid>
													<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
													  <Col xs={3} style={{ marginTop: 8}}>
													  </Col>
													  <Col xs={1} style={{ marginTop: 6}}>
														<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
															{tabExportData.map((excelFormField, index) => {
																return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
															})}
															<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
															  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
															</OverlayTrigger>
														</form>
													  </Col>
													  <Col xs={1} style={{ marginTop: 6}}>
														<form target='_blank' action={this.getConfig().host + '/HeLAD/ReturnManifest'} method='POST'>
															{tabManifestData.map((pdfFormField, index) => {
																return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
															})}
															<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Manifest</Popover.Header></Popover>}>
															  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="PrintReceipt" />
															</OverlayTrigger>
														</form>
													  </Col>
													</Form.Group>
												</Container>
											</div>
										);
									})}
								</Tabs>
							</Col>
						</Row>
					}
					{this.state.returnManifestLists.length === 1 && <>
						<Row>
							<Col>
								<span style={{ fontSize: '18px', fontWeight: 'bold' }}>{activeManifestList.tab}</span>
							</Col>
						</Row>
						<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						  <Col xs={3} style={{ marginTop: 8}}>
						  </Col>
						  <Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						  </Col>
						  <Col xs={1} style={{ marginTop: 6}}>
							<form target='_blank' action={this.getConfig().host + '/HeLAD/ReturnManifest'} method='POST'>
								{manifestData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Manifest</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="PrintReceipt" />
								</OverlayTrigger>
							</form>
						  </Col>
						</Form.Group>
					</>}
				  </Container>
				</Col>
			  </Row>
			  {activeManifestList &&
				  <Row>
					<Col>
					  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_ReturnManifest'
						table={activeManifestList} onResult={(data) => this.onSearchResult(data)} paging={false} />
					</Col>
				  </Row>
			  }
			</Container>
		</>);		
	}
}

const returnManifestSearch = [
	{ Header: 'Return Tag Number', accessor: 'TAG_NUM_OUT', type: 'select',
		options: { value: 'TAG_NUM_OUT', label: 'TAG_NUM_OUT_DISPLAY', entity: 'DEMURRAGE.TAG_NUM_OUT_SELECT_VW', order: ['DATE_OUT_COLLEGE desc','TAG_NUM_OUT'] }}
];

const returnManifestList = {
	query: { entity: 'DEMURRAGE.RETURN_MANIFEST_VW', search: null, order: 'MFR_CODE' },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editDemTransaction(props.row.values.CYCLE_ID)} /> },
		{ Header: '', accessor: 'CYCLE_ID', show: false },
		{ Header: 'Mfr Code', accessor: 'MFR_CODE', width: 150,
			Cell: props => <HashLink onClick={() => props.parent.viewDemTransaction(props.row.values.CYCLE_ID)}>{props.value}</HashLink> },
		{ Header: 'Gas', accessor: 'GAS_NAME', width: 300, }
	]
};

const exportColumns = [
	{ Header: 'Mfr Code', accessor: 'MFR_CODE', width: 30 },
	{ Header: 'Gas', accessor: 'GAS_NAME', width: 60, }
];

export default withRouter(ReturnManifest);
